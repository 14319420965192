import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {Dictionary, DictionarySelector, FieldProps} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-dictionary-dropdown',
  templateUrl: './dictionary-dropdown.component.html',
  styleUrls: ['./dictionary-dropdown.component.scss']
})
export class DictionaryDropdownComponent extends BaseConfigurableComponent implements DictionarySelector {
  @Input() currentDictionary?: number
  @Input() dictionaries?: Dictionary[]
  selectedDictionaryName?: string

  @Output() selectDictionary = new EventEmitter<number>()

  static readonly compName = 'DictionaryDropdownComponent';
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {key: 'defaultValue', label: 'Default dictionary ID', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(DictionaryDropdownComponent, DictionaryDropdownComponent.compName, 'Dropdown List', [PageCompType.DictionarySelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<DictionarySelector>(DictionaryDropdownComponent, this.name, initialConfig);
    }
  }

  override doUpdate(): void {
    super.doUpdate();
    this.updateDictName(this.currentDictionary)
  }

  private updateDictName(newId?: number) {
    this.selectedDictionaryName = this.dictionaries?.find(l => l.id === newId)?.name;
  }

  onSelect(id?: number) {
    this.selectDictionary.emit(id)
    this.updateDictName(id);
  }
}
