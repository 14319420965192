import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {ClientConfigurationService, ComponentDeclarationService} from '@twpub/core/services';
import {ALL_COMP_TYPES} from '@twpub/core/enums';
import {ClientConfiguration} from '@twpub/core/utils';
import {finalize} from 'rxjs';
import {NotifierService} from '../../services/notifier.service';

@Component({
  selector: 'pub-edit-configuration-form',
  templateUrl: './edit-configuration-form.component.html',
  styleUrls: ['./edit-configuration-form.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class EditConfigurationFormComponent implements OnInit {
  @Input() editConfiguration!: ClientConfiguration;
  @Output() editingDone = new EventEmitter()

  form: UntypedFormGroup = this.fb.group({
    name: ['', [this.existingNameValidator()]],
    css: ''
  });
  private configs: ClientConfiguration[] = [];

  existingNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = control.value.trim();
      if (!name) {
        return {empty: true};
      }
      const configByName = this.configService.getByName(this.configs, name);
      if (configByName && configByName.id !== this.editConfiguration?.id) {
        return {nameExists: true}
      }
      return null;
    }
  }

  constructor(private fb: UntypedFormBuilder, private cds: ComponentDeclarationService,
              private configService: ClientConfigurationService, private notifier: NotifierService) {
  }

  ngOnInit(): void {
    this.form.setValue({
      name: this.editConfiguration.name || '',
      css: this.editConfiguration.css || ''
    });
    this.configService.getConfigurations().subscribe({
      next: (configs) => {
        this.configs = configs
      }
    });
  }

  isEditing(): boolean {
    return this.editConfiguration?.id !== 0;
  }

  saveEditConfiguration(close: boolean = false) {
    this.updateEditConfig();
    this.configService.updateConfiguration(this.editConfiguration).pipe(
      finalize(() => {
        const name = this.editConfiguration.name;
        const message = this.isEditing() ? `Configuration ${name} updated` : `Configuration ${name} created`
        this.notifier.showPopupMessage(message, 'success', true)
        if (close) {
          this.editingDone.emit();
        }
      })
    ).subscribe();
  }

  /**
   * Updates the edit configuration with values from the form.
   */
  private updateEditConfig(): void {
    const f = this.form.value;
    this.editConfiguration.name = f.name.trim();
    this.editConfiguration.css = f.css.trim();
    this.updateComponentConfigs();
    this.editConfiguration.version++;
  }

  /**
   * Updates all component configurations with values from the form.
   */
  updateComponentConfigs() {
    const f = this.form.value;
    const editTypes = ALL_COMP_TYPES;
    const excludedProps = ['componentType']; // Fields set explicity
    editTypes.forEach((compType: string) => {
      const formComp = f[compType];
      const oldCompConfig = this.editConfiguration.getConfigurationSafe(compType);
      const compConfig = this.cds.createComponentConfiguration(formComp.componentType, oldCompConfig?.config);
      if (compConfig) {
        Object.keys(formComp).forEach((key) => {
          if (!excludedProps.includes(key)) {
            compConfig.setConfigValue(key, formComp[key]);
          }
        })
        this.editConfiguration.setConfiguration(compType, compConfig);
      }
    });
  }

  cancelEdit() {
    this.editingDone.emit();
  }

  hasError = (ctrlName: string, errName: string) => this.form.get(ctrlName)?.hasError(errName);
}
