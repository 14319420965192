import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output} from '@angular/core';
import {ConceptSelection, DisplayField} from '@twpub/core/models';
import {ContentItem, RefLinkContentParser} from '@twpub/core/services/ref-link-content-parser';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[pub-field-render]',
  templateUrl: './field-render.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldRenderComponent implements OnChanges {
  @Input() field!: DisplayField;
  @Input() useRouterLink: boolean = false;

  @Output() conceptSelected = new EventEmitter<ConceptSelection>();
  contentItems: ContentItem[] = [];

  @HostBinding('class') get fieldClass() {
    return 'f f-' + this.field.oid;
  }

  constructor(private parser: RefLinkContentParser) {
  }

  ngOnChanges(): void {
    this.contentItems = this.parser.parse(this.field.value, this.field.refLinks);
    this.contentItems.forEach((item) => {
      item.isVirtualFile = this.parser.isVirtualFile(item);
      item.fileId = this.parser.getFileId(item);
    });
  }

  selectConcept(selection: ConceptSelection) {
    this.conceptSelected.emit(selection);
  }
}
