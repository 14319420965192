import {Injectable} from '@angular/core';
import {ClientConfigurationService} from './client-configuration.service';
import {NGXLogger} from 'ngx-logger';
import {from, Observable, switchMap} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakConfig} from 'keycloak-js';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(private logger: NGXLogger, private configService: ClientConfigurationService,
              private kcService: KeycloakService) {
  }

  initApp() {
    this.logger.debug('InitService.initApp:')
    return this.initKeycloak()
  }

  private initKeycloak(): Observable<boolean> {
    return this.configService.getAuthConfiguration().pipe(switchMap(authConfig => {
      const config: KeycloakConfig = {
        url: environment.keycloak?.url,
        realm: environment.keycloak?.realm || authConfig.realm,
        clientId: environment.keycloak?.clientId || authConfig.clientId
      };
        // todo Find current language dynamically for url (or use common assets folder)
      const langPrefix = environment.production ? '/en': '';
      const initOptions: Keycloak.KeycloakInitOptions = {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + langPrefix+ '/assets/silent-check-sso.html'
      }
      this.logger.debug('InitService.initKeycloak: config=' + JSON.stringify(config) + ' initOptions=' + JSON.stringify(initOptions))
      return from(this.kcService.init({
        config,
        initOptions,
      }));
    }));
  }
}
