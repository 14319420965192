import {Component, Injector, OnChanges, ViewChild} from '@angular/core';
import {PreambleDirective} from './preamble.directive';
import {PageCompType} from '@twpub/core/enums';
import {Preamble} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-preamble-wrapper',
  templateUrl: './preamble-wrapper.component.html',
  styleUrls: ['./preamble-wrapper.component.scss']
})
export class PreambleWrapperComponent extends BaseWrapperComponent<Preamble> implements OnChanges {
  override type = PageCompType.Preamble;

  @ViewChild(PreambleDirective, {static: true}) pubPreamble!: PreambleDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  ngOnChanges(): void {
    this.update();
  }

  getViewContainerRef() {
    return this.pubPreamble.viewContainerRef;
  }
}
