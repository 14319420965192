import {Injectable, Injector} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient} from '@angular/common/http';
import {url as httpUrl} from '../utils/http-utils';
import {PersistentObject} from '@twpub/core/models';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  protected logger: NGXLogger;
  protected http: HttpClient;

  protected constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.http = injectorObj.get(HttpClient);
  }

  protected url(uri: string): string {
    return httpUrl(uri);
  }

  /**
   * Check if an object with the given id exists in the given array of persistent objects.
   * @param id The id.
   * @param objArr The array of persistent objects.
   */
  exists(id: number | undefined, objArr: PersistentObject[]): boolean {
    return objArr.some((p) => p.id === id);
  }
}
