import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core'
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, Language, LanguageSelector} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'pub-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent extends BaseConfigurableComponent implements LanguageSelector {
  static readonly compName = 'LanguageDropdownComponent';
  static readonly MULTIPLE_SELECT = 'multipleSelect';

  @Input() currentLanguages?: string[];
  @Input() langs?: Language[]

  @Output() selectLanguages: EventEmitter<string[]> = new EventEmitter<string[]>()
  multipleSelect: boolean = false;
  allSelected: boolean = false;
  singleSelected: boolean = false;
  readonly allSelectedLabel: string = 'All Languages';

  @ViewChild('select') select!: MatSelect;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      // {key: 'allLanguages', label: 'Include \'all languages\'', controlType: Boolean},
      {
        key: LanguageDropdownComponent.MULTIPLE_SELECT,
        label: 'Multiple selections',
        controlType: ControlType.Boolean,
        description: 'Allow multiple selections in dropdown'
      },
      {key: 'defaultValue', label: 'Default language code', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(LanguageDropdownComponent, LanguageDropdownComponent.compName, 'Dropdown List', [PageCompType.SourceLanguageSelector, PageCompType.TargetLanguageSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<LanguageSelector>(LanguageDropdownComponent, this.name, initialConfig);
    }
  }

  override doUpdate() {
    super.doUpdate();
    this.multipleSelect = this.config?.getBoolean(LanguageDropdownComponent.MULTIPLE_SELECT, false);
    this.allSelected = !this.currentLanguages?.length;
    this.singleSelected = this.currentLanguages?.length === 1;
  }

  onSelect(selValue?: string | string[]) {
    this.logger.trace('LanguageDropdownComponent.onSelect:', {selValue})
    if (!this.langs?.length) return;
    let selectedCodes: string[];
    if (typeof selValue === 'string') {
      selectedCodes = selValue === '' ? [] : [selValue];
    } else {
      selectedCodes = selValue || [];
    }
    const isAllSelected = selectedCodes.length === this.langs?.length;
    const isNoneSelected = selectedCodes.length === 0;
    selectedCodes = isAllSelected ? [] : isNoneSelected ? [this.langs[0].code] : selectedCodes;
    this.allSelected = isAllSelected;
    this.singleSelected = selectedCodes.length === 1;
    this.currentLanguages = selectedCodes;
    this.selectLanguages.emit(selectedCodes)
  }

  toggleAllSelection(all: boolean = true) {
    this.select.options.forEach((item: MatOption) => {
      if (all) {
        item.value !== '' && item.select();
      } else {
        item.deselect()
      }
    });
  }

  getSelectedValue() {
    let value: string[] | string;
    if (this.multipleSelect) {
      if (this.currentLanguages?.length) {
        value = this.currentLanguages;
      } else {
        value = this.langs?.map(s => s.code) || [];
      }
    } else {
      value = this.currentLanguages?.[0] || '';
    }
    return value;
  }

  getLanguageName(lang: Language) {
    return lang.name;
  }

  isDisabled(language: Language) {
    return this.multipleSelect && this.currentLanguages?.length === 1 && this.currentLanguages?.[0] === language.code;
  }
}
