<ng-container *ngIf="showVideoInline()">
  <video class="inline mat-elevation-z8" controls [src]="contentUrl"></video>
</ng-container>
<ng-container *ngIf="showAudioInline()">
  <audio class="inline" controls [src]="contentUrl"></audio>
</ng-container>
<ng-container *ngIf="showImageInline()">
  <a class="icon-link" role="button" (click)="openContentDialog($event)">
    <img class="inline mat-elevation-z8" [alt]="vfile?.description" [src]="thumbUrl">
    <!--       [attr.imageSrc]="contentUrl" [attr.thumbSrc]="thumbUrl"-->
  </a>
</ng-container>

<ng-container *ngIf="showLink()">
  <a class="icon-link" role="button" (click)="openContentDialog($event)">{{ getLinkName() }}
    <mat-icon inline="true">{{ getIcon() }}</mat-icon>
  </a>
</ng-container>
