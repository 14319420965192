import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {A11yPageWrapperComponent} from '@twpub/shared/components/wrappers';

const routes: Routes = [
  {path: 'tillganglighetsredogorelse', component: A11yPageWrapperComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AfUiRoutingModule {
}
