import {Component, Inject, OnInit} from '@angular/core';
import {ClientConfiguration} from '@twpub/core/utils';
import {ClientConfigurationService, FeatureFlagService} from '@twpub/core/services';
import {LOCATION} from '@ng-web-apis/common';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'pub-configuration-selector',
  templateUrl: './configuration-selector.component.html',
  styleUrls: ['./configuration-selector.component.scss']
})
export class ConfigurationSelectorComponent implements OnInit {
  configurations: ClientConfiguration[] = [];
  enabled: boolean = false;

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService, private featureFlagService: FeatureFlagService,
              @Inject(LOCATION) private location: Location) {
    this.logger.debug('feature ConfigurationSelectorComponent.constructor:', {})
  }

  ngOnInit(): void {
    this.configService.getConfigurations().subscribe((configs) => {
      this.configurations = configs;
    });
  }

  isEnabled(): boolean {
    return this.featureFlagService.isFeatureEnabled('ConfigurationSelectorComponent');
  }

  isVisible(): boolean {
    return this.configurations.length > 1;
  }

  onClick(id: number) {
    this.location.replace('/layout/' + id);
  }
}
