<div class="result-container" [ngClass]="cssClassName">
  <as-split direction="horizontal">
    <as-split-area [size]="25" class="result-list">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="termName">
          <td mat-cell *matCellDef="let resData">
            <pub-source-term-name [term]="toRes(resData).term"
                                  [showLang]="hasMultipleSourceLangs()"></pub-source-term-name>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let res; columns: ['termName'];" (click)="resultClick(res)"></tr>
      </table>
      <mat-paginator *ngIf="dataSource.data?.length && resultPaginator" [length]="resultPaginator.totalItems"
                     [pageSize]="resultPaginator.pageSize"
                     [pageIndex]="resultPaginator.pageIndex" [pageSizeOptions]="pageSizeOptions"
                     (page)="pageChange($event)" [showFirstLastButtons]="true"></mat-paginator>
      <div *ngIf="hasNoResults()" class="not-found">No results</div>
    </as-split-area>
    <as-split-area [size]="75" class="result-details">
      <div *ngIf="sessionObj.conceptId">
        <pub-result-view-wrapper [sessionObj]=sessionObj
                                 (conceptSelected)="selectConceptFromView($event)"></pub-result-view-wrapper>
      </div>
    </as-split-area>
  </as-split>
</div>
