import {Component, forwardRef, Injector, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {hasSessionChanges} from '@twpub/core/utils';
import {DomainService} from '@twpub/core/services/domain.service';
import {DomainTree} from '@twpub/core/models/domain-tree';
import {DomainSelector} from '@twpub/core/models/wrappers/domain-selector';
import {DomainSelectorDirective} from '@twpub/shared/components/wrappers';

@Component({
  selector: 'pub-domain-wrapper',
  templateUrl: './domain-wrapper.component.html',
  styleUrls: ['./domain-wrapper.component.scss']
})
export class DomainWrapperComponent extends BaseWrapperComponent<DomainSelector> implements OnChanges {
  override type = PageCompType.DomainSelector;
  domainTree?: DomainTree;

  @ViewChild(forwardRef(() => DomainSelectorDirective), {static: true}) pubDomainSelect!: DomainSelectorDirective

  constructor(private injector: Injector, private domainService: DomainService) {
    super(injector)
  }

  protected override init() {
    this.fetchDomains();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.DICT_ID, SessionParam.DOMAIN_IDS])) {
      if (hasSessionChanges(changes, [SessionParam.DICT_ID])) {
        this.fetchDomains();
      } else {
        this.update();
      }
    }
  }

  private fetchDomains() {
    const dict = this.sessionObj?.dictId;
    if (dict) {
      this.domainService.getDomainTree(dict).subscribe({
        next: (tree) => {
          this.domainTree = tree;
          this.update();
        }
      })
    }
  }

  getViewContainerRef() {
    return this.pubDomainSelect.viewContainerRef;
  }

  override createComponent(): DomainSelector {
    const comp = super.createComponent();
    comp.selectDomains.subscribe((domains: number[]) => this.sharedStateService.selectDomains(domains))
    this.fetchDomains()
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentDomainIds = this.sessionObj.domainIds || [];
      comp.domainTree = this.domainTree;
    })
  }
}
