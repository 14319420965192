import {Component, Injector, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {SectionSelector} from '@twpub/core/models/wrappers/section-selector';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {SectionSelectorDirective} from './section-selector.directive';
import {Section} from '@twpub/core/models';
import {DictionaryService} from '@twpub/core/services';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-section-wrapper',
  templateUrl: './section-wrapper.component.html',
  styleUrls: ['./section-wrapper.component.scss']
})
export class SectionWrapperComponent extends BaseWrapperComponent<SectionSelector> implements OnChanges {
  override type = PageCompType.SectionSelector;
  sections: Section[] = [];

  @ViewChild(SectionSelectorDirective, {static: true}) pubSectionSelect!: SectionSelectorDirective

  constructor(private injector: Injector, private dictService: DictionaryService) {
    super(injector)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.DICT_ID, SessionParam.SECTION_OIDS])) {
      if (hasSessionChanges(changes, [SessionParam.DICT_ID])) {
        this.fetchSections();
      } else {
        this.update();
      }
    }
  }

  private fetchSections() {
    const dict = this.sharedStateService.getDictionaryId();
    if (dict) {
      this.dictService.getSections(dict).subscribe({
        next: (sections) => {
          this.sections = sections;
          this.update();
        }
      })
    }
  }

  getViewContainerRef() {
    return this.pubSectionSelect.viewContainerRef;
  }

  override createComponent(): SectionSelector {
    const comp = super.createComponent();
    comp.selectSections.subscribe((event: string[]) => this.sharedStateService.selectSections(event))
    this.fetchSections()
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentSections = this.sharedStateService.getSectionOids();
      comp.sections = this.sections;
    })
  }
}
