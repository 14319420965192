import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {ConceptSelection, SessionObject} from '@twpub/core/models';

@Component({
  selector: 'pub-concept-view-dialog',
  templateUrl: './concept-view-dialog.component.html',
  styleUrls: ['./concept-view-dialog.component.scss']
})
export class ConceptViewDialogComponent {
  sessionObj: SessionObject;
  conceptId?: number;
  termId?: number;

  constructor(private logger: NGXLogger, private dialog: MatDialog,
              public dialogRef: MatDialogRef<ConceptViewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { sessionObj: SessionObject }) {
    this.sessionObj = data.sessionObj;
  }

  selectConcept(selection: ConceptSelection) {
    this.logger.trace('ConceptViewDialogComponent.selectConcept:', {selection})
    this.sessionObj.conceptId = selection.conceptId;
    this.sessionObj.termId = selection.termId;
    this.conceptId = selection.conceptId;
    this.termId = selection.termId;
  }
}
