import {NgModule} from '@angular/core';
import {AdminPageComponent} from './pages/admin-page/admin-page.component';
import {TermwebStatusComponent} from './components/termweb-status/termweb-status.component';
import {ConfigurationListComponent} from './components/configuration-list/configuration-list.component';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EditConfigurationFormComponent} from './components/edit-configuration-form/edit-configuration-form.component';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {ComponentEditorComponent} from './components/component-editor/component-editor.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ComponentEditorFieldComponent} from './components/component-editor-field/component-editor-field.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {ComponentEditorsListComponent} from './components/component-editors-list/component-editors-list.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AdminRoutingModule} from './admin-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {ConfigurationsPanelComponent} from './components/configurations-panel/configurations-panel.component';
import {SettingsPanelComponent} from './components/settings-panel/settings-panel.component';
import {DictionariesPanelComponent} from './components/dictionaries-panel/dictionaries-panel.component';
import {MatTableModule} from '@angular/material/table';
import {SyncStatusIndicatorComponent} from './components/sync-status-indicator/sync-status-indicator.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {AdminHomeComponent} from './components/admin-home/admin-home.component';
import {DictionaryDetailsComponent} from './components/dictionary-details/dictionary-details.component';
import {SectionsPanelComponent} from './components/sections-panel/sections-panel.component';
import {DatePipe} from '@angular/common';
import {LanguagesPanelComponent} from './components/languages-panel/languages-panel.component';
import {ScheduledSyncSettingsComponent} from './components/scheduled-sync-settings/scheduled-sync-settings.component';
import {
  TermwebConnectionSettingsComponent
} from './components/termweb-connection-settings/termweb-connection-settings.component';
import {UploadDialogComponent} from './components/confirm-dialog/upload-dialog/upload-dialog.component';
import {
  TermwebConnectionSettingsFormComponent
} from './components/termweb-connection-settings-form/termweb-connection-settings-form.component';
import {
  TermwebConnectionSettingsDisplayComponent
} from './components/termweb-connection-settings-display/termweb-connection-settings-display.component';

@NgModule({
  declarations: [
    AdminPageComponent,
    TermwebStatusComponent,
    ConfigurationListComponent,
    EditConfigurationFormComponent,
    ComponentEditorComponent,
    ComponentEditorFieldComponent,
    ComponentEditorsListComponent,
    ConfigurationsPanelComponent,
    SettingsPanelComponent,
    DictionariesPanelComponent,
    SyncStatusIndicatorComponent,
    ConfirmDialogComponent,
    IconButtonComponent,
    AdminHomeComponent,
    DictionaryDetailsComponent,
    SectionsPanelComponent,
    LanguagesPanelComponent,
    ScheduledSyncSettingsComponent,
    TermwebConnectionSettingsComponent,
    UploadDialogComponent,
    TermwebConnectionSettingsFormComponent,
    TermwebConnectionSettingsDisplayComponent
  ],
  imports: [
    SharedModule,
    AdminRoutingModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    FormsModule,
    MatCardModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    MatTabsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  providers: [DatePipe]
})
export class AdminModule {
}
