import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  constructor() {
  }

  getImageFields(): string[] {
    // const fieldNames = this.config.getString(ResultListProp.ImageFields);
    const fieldNames = 'Image;Term Image';
    return fieldNames?.split(';').map(f => f.trim()) || [];
  }
}
