import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {NGXLogger} from 'ngx-logger';
import {DictionaryDropdownComponent} from './components/dictionary-dropdown/dictionary-dropdown.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {LanguageDropdownComponent} from './components/language-dropdown/language-dropdown.component';
import {DefaultSearchPageComponent} from './pages/default-search-page/default-search-page.component';
import {DefaultHeaderComponent} from './components/default-header/default-header.component';
import {DefaultFooterComponent} from './components/default-footer/default-footer.component';
import {DefaultPreambleComponent} from './components/default-preamble/default-preamble.component';
import {ComboResultListComponent} from './components/combo-result-list/combo-result-list.component';
import {ShortResultViewComponent} from './components/short-result-view/short-result-view.component';
import {DefaultResultListComponent} from './components/default-result-list/default-result-list.component';
import {DefaultResultViewComponent} from './components/default-result-view/default-result-view.component';
import {LanguageRadioComponent} from './components/language-radio/language-radio.component';
import {DefaultSearchFieldComponent} from './components/default-search-field/default-search-field.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ComponentDeclarationService} from '@twpub/core/services';
import {SectionDropdownComponent} from './components/section-dropdown/section-dropdown.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  SourceTermNameRenderComponent
} from '@twpub/shared/components/renders/source-term-name-render/source-term-name-render.component';
import {AngularSplitModule} from 'angular-split';
import {
  DefaultAttachmentRenderComponent
} from './components/default-attachment-render/default-attachment-render.component';
import {DomainDropdownComponent} from './components/domain-dropdown/domain-dropdown.component';
import {CardResultListComponent} from './components/card-layout/card-result-list/card-result-list.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {CardContentComponent} from './components/card-layout/card-content/card-content.component';
import {GallerizeDirective, LightboxDirective} from 'ng-gallery/lightbox';
import {CardImageComponent} from './components/card-layout/card-image/card-image.component';
import {NgOptimizedImage} from '@angular/common';
import {DefaultResultPageComponent} from './pages/default-result-page/default-result-page.component';
import {CardResultViewComponent} from './components/card-layout/card-result-view/card-result-view.component';
import {ImageGalleryComponent} from './components/card-layout/image-gallery/image-gallery.component';
import {NoImageComponent} from './components/card-layout/card-image/no-image/no-image.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DomainIndentedListComponent} from './components/domain-indented-list/domain-indented-list.component';
import {RenderRelationsComponent} from './components/render-relations/render-relations.component';
import {RenderRelationComponent} from './components/render-relation/render-relation.component';
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [
    DefaultSearchPageComponent,
    DefaultResultPageComponent,
    DictionaryDropdownComponent,
    LanguageDropdownComponent,
    DefaultHeaderComponent,
    DefaultFooterComponent,
    DefaultPreambleComponent,
    ComboResultListComponent,
    ShortResultViewComponent,
    DefaultResultListComponent,
    DefaultResultViewComponent,
    LanguageRadioComponent,
    DefaultSearchFieldComponent,
    SectionDropdownComponent,
    SourceTermNameRenderComponent,
    DefaultAttachmentRenderComponent,
    DomainDropdownComponent,
    CardResultListComponent,
    CardContentComponent,
    CardImageComponent,
    CardResultViewComponent,
    DomainIndentedListComponent,
    RenderRelationsComponent,
    RenderRelationComponent,
  ],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatButtonToggleModule,
        MatTooltipModule,
        AngularSplitModule,
        MatGridListModule,
        GallerizeDirective,
        LightboxDirective,
        NgOptimizedImage,
        ImageGalleryComponent,
        NoImageComponent,
        MatSidenavModule,
        MatCheckboxModule,
        MatMenuModule
    ]
})
export class TwpubUiModule {
  readonly components = [DefaultSearchPageComponent, DefaultResultPageComponent,
    DictionaryDropdownComponent, SectionDropdownComponent,
    DomainDropdownComponent, DomainIndentedListComponent, LanguageDropdownComponent, DefaultPreambleComponent,
    DefaultHeaderComponent, DefaultFooterComponent, ComboResultListComponent, ShortResultViewComponent,
    DefaultResultListComponent, DefaultResultViewComponent, LanguageRadioComponent, DefaultSearchFieldComponent,
    DefaultAttachmentRenderComponent, CardResultListComponent, CardResultViewComponent
  ];

  /**
   * Register all components in this module with the component declaration service.
   * @param compService The component declaration service.
   * @param logger The logger.
   */
  constructor(compService: ComponentDeclarationService, logger: NGXLogger) {
    logger.debug('TwpubUiModule registers components...');
    this.components.forEach(c => compService.registerComponent(c, new c.Init()));
  }
}
