<div class="container p-4">
  <table mat-table [dataSource]="configurations" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let config"> {{ config.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let config"> {{ config.name }}</td>
    </ng-container>

    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef> Default</th>
      <td mat-cell *matCellDef="let config">
        <pub-icon-button tooltip="Make default"
                         (buttonClick)="selectConfig(config)"
                         [disabled]="isSelected(config)"
                         [icon]="config.id===defaultId?'radio_button_checked':'radio_button_unchecked'"
                         [hoverIcon]="!isSelected(config)?'radio_button_checked':undefined"
        ></pub-icon-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let config">
        <pub-icon-button tooltip="Edit" (buttonClick)="editConfig(config.id)" icon="edit"
                         position="below"></pub-icon-button>
        <pub-icon-button tooltip="Copy" (buttonClick)="copyConfig(config.id)" icon="content_copy"
                         position="below"></pub-icon-button>
        <pub-icon-button tooltip="Download" (buttonClick)="downloadConfig(config.id)"
                         icon="download" position="below"></pub-icon-button>
        <pub-icon-button tooltip="Delete" (buttonClick)="deleteConfig(config.id)" color="warn"
                         icon="delete_forever" position="below"></pub-icon-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'row-disabled':!row.visible, 'selected':isSelected(row)}">
    </tr>
  </table>
  <div class="button-container">
    <button mat-raised-button (click)="createConfig()" color="primary">
      <mat-icon>create"</mat-icon>
      <span class="icon-text">Create new configuration</span>
    </button>
    <button mat-raised-button (click)="importConfig()" color="primary">
      <mat-icon>upload_file"</mat-icon>
      <span class="icon-text">Import configuration</span>
    </button>
  </div>
</div>
