<h2 mat-dialog-title>{{ vfile?.name }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="isImage()">
    <a [href]="contentUrl" [tabIndex]="-1" target="_blank">
      <img class="dialog mat-elevation-z8" [alt]="vfile?.description" [src]="contentUrl">
    </a>
  </ng-container>
  <ng-container *ngIf="isAudio()">
    <audio controls [src]="contentUrl" autoplay></audio>
  </ng-container>
  <ng-container *ngIf="isVideo()">
    <video class="dialog mat-elevation-z8" controls [src]="contentUrl" autoplay></video>
  </ng-container>
  <ng-container *ngIf="isPdf()">
    <object class="dialog" [data]="contentUrl"></object>
  </ng-container>
  <div *ngIf="openLink" class="margin-top-15">
    <a [href]="contentUrl" [tabIndex]="-1" target="_blank" class="icon-link">
      Open file in new window
      <mat-icon [inline]="true">open_in_new</mat-icon>
    </a>
  </div>
  <div *ngIf="downloadLinkName" class="margin-top-15">
    <a [href]="downloadUrl" [tabIndex]="-1" target="_blank" class="icon-link">
      {{ downloadLinkName }}
      <mat-icon [inline]="true">download</mat-icon>
    </a>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onConfirm()" color="primary">Close</button>
</mat-dialog-actions>
