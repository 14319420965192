<!--suppress AngularUndefinedBinding -->
<ng-container *ngIf="visible && langs?.length">
  <form [formGroup]="selectLangForm">
    <digi-form-select class="language-dropdown"
                      [afLabel]="label"
                      [afValue]="currentLanguages?.[0]"
                      formControlName="selectLang"
                      (afOnChange)="onSelectLanguage()"
    >
      <option *ngFor="let lang of langs" [value]="lang.code">{{getLanguageName(lang)}}</option>
    </digi-form-select>
  </form>
</ng-container>
