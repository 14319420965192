import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedStateService} from '@twpub/core/services';

@Component({
  template: '',
})
export class SetConfigurationComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private sharedStateService: SharedStateService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const configId = params['id'];
      this.sharedStateService.selectConfiguration(configId)
      this.router.navigate(['/']);
    });
  }
}
