import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TermwebConfiguration} from '@twpub/core/models';
import {DictionaryService} from '@twpub/core/services';

@Component({
  selector: 'pub-termweb-connection-settings-display',
  templateUrl: './termweb-connection-settings-display.component.html',
  styleUrls: ['./termweb-connection-settings-display.component.scss']
})
export class TermwebConnectionSettingsDisplayComponent implements OnInit {
  @Input() configuration!: TermwebConfiguration;

  @Output() editConfiguration: EventEmitter<TermwebConfiguration> = new EventEmitter<TermwebConfiguration>();

  editDisabled: boolean = true;

  constructor(private dictService : DictionaryService) {
  }

  ngOnInit(): void {
    this.dictService.getDictionaryStatistics().subscribe({
      next: (stats) => {
        this.editDisabled = stats.some(stat => stat.publishedConceptCount > 0);
      }
    })
  }

  edit() {
    this.editConfiguration.emit(this.configuration);
  }
}
