export interface ConceptRelation {
  id: number;
  dictionaryId: number;
  sourceConceptId: number;
  targetConceptId: number;
  type: number;
  attribute: number;
  text: string;
  relationId: number; // ID within the relation's source concept
}

export enum ConceptRelationAttribute {
  GENERIC_SUPERORDINATE,
  GENERIC_SUBORDINATE,

  PARTITIVE_SUPERORDINATE,
  PARTITIVE_SUBORDINATE,

  TEMPORAL_BEFORE,
  TEMPORAL_CONCURRENT,
  TEMPORAL_AFTER,

  SPATIAL_UP,
  SPATIAL_DOWN,
  SPATIAL_RIGHT,
  SPATIAL_LEFT,

  ASSOCIATIVE_DEFAULT,
}
