import {Component, Input} from '@angular/core';

@Component({
  selector: 'pub-no-image',
  templateUrl: './no-image.component.html',
  styleUrls: ['./no-image.component.scss'],
  standalone: true
})
export class NoImageComponent {
  @Input() size: string = '120px';
}
