import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private isoCodes: { [iso3Code: string]: string } = {
    ara: 'ar',
    ben: 'bn',
    deu: 'de',
    eng: 'en',
    spa: 'es',
    fas: 'fa',
    fra: 'fr',
    hin: 'hi',
    ita: 'it',
    jav: 'jv',
    jpn: 'ja',
    kor: 'ko',
    msa: 'ms',
    pol: 'pl',
    por: 'pt',
    rus: 'ru',
    swe: 'sv',
    tur: 'tr',
    ukr: 'uk',
    vie: 'vi',
    zho: 'zh'
  };

  getIso2Code(iso3Code: string = ''): string {
    return this.isoCodes[iso3Code];
  }
}
