import {Component, Input} from '@angular/core';

@Component({
  selector: 'pub-sync-status-indicator',
  templateUrl: './sync-status-indicator.component.html',
  styleUrls: ['./sync-status-indicator.component.scss']
})
export class SyncStatusIndicatorComponent {
  @Input() status?: string;
  @Input() message: string = '';
}
