import {PersistentObject} from '@twpub/core/models/persistent-object'

export enum RefLinkType {
  UNKNOWN_LINK_TYPE = 0,
  TERM_LINK_TYPE = 1,
  CONCEPT_LINK_TYPE = 2,
  EXTERNAL_LINK_TYPE = 3,
  TERM_LINK_DYNAMIC_TYPE = 4
}

export interface RefLink extends PersistentObject {
  dictionaryId: number
  sourceConceptId: number
  sourceTermId?: number
  sourceFieldOid: string
  type: RefLinkType
  text: string
  dynamicText?: string
  position: number
  url?: string
  conceptId?: number
  termId?: number
  invalid?: boolean
}
