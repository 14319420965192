<div class="custom-grid">
  <div class="grid-item" *ngFor="let tile of tiles" [style.grid-column]="tile.cols"
       [style.background]="debug ? tile.color: 'transparent'">
    <ng-container *ngTemplateOutlet="tile.tpl"></ng-container>
  </div>
</div>

<ng-template #headerTemplate>
  <div>
    <!-- Custom content for header -->
    <pub-domain-breadcrumbs (selectDomains)="selectDomains($event)"
                            [path]="concept?.domainPaths?.[0]"></pub-domain-breadcrumbs>
  </div>
</ng-template>

<ng-template #leftTemplate>
  <div *ngIf="concept">
    <pub-image-gallery [entryId]="concept.id" [fileIds]="fileIds"></pub-image-gallery>
  </div>
</ng-template>

<ng-template #rightTemplate>
  <div *ngIf="concept" [ngClass]="cssClassName">
    <pub-term-render [term]="getTerm()" [focus]="true"
                     (conceptSelected)="selectConcept($event)"></pub-term-render>

    <hr/>
    <div *ngIf="classificationFields?.length" class="fset ccl">
      <div pub-field-render *ngFor="let field of classificationFields" [field]="field"
           (conceptSelected)="selectConcept($event)"></div>
    </div>
    <div *ngIf="conceptFields?.length" class="fset cl">
      <div pub-field-render *ngFor="let field of conceptFields" [field]="field"
           (conceptSelected)="selectConcept($event)"></div>
    </div>
    <hr/>
    <pub-render-relations [conceptId]="concept.id"></pub-render-relations>
  </div>
</ng-template>
