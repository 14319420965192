import {Component, Input, OnInit} from '@angular/core';
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {ComponentConfiguration} from '@twpub/core/utils';
import {ComponentFieldModel, ConfigurableComponent} from '@twpub/core/models';
import {ComponentDeclarationService} from '@twpub/core/services';
import {ALL_COMP_TYPES, getPageCompTypeName, PageCompType} from '@twpub/core/enums';

@Component({
  selector: 'pub-component-editor',
  templateUrl: './component-editor.component.html',
  styleUrls: ['./component-editor.component.scss']
})
export class ComponentEditorComponent implements OnInit {
  @Input() scheme!: string;
  @Input() compType!: PageCompType | string;
  @Input() compConfig!: ComponentConfiguration<ConfigurableComponent>;

  fieldModels: ComponentFieldModel<any>[] = [];
  formGroup!: UntypedFormGroup;

  constructor(
    private logger: NGXLogger,
    private compDeclarationService: ComponentDeclarationService,
    private parent: FormGroupDirective
  ) {
  }

  ngOnInit() {
    const compModel = this.compDeclarationService.getModel(this.scheme, this.compType, this.compConfig);
    this.fieldModels = compModel.componentFields;
    this.formGroup = this.toFormGroup(compModel.componentFields as ComponentFieldModel<any>[]);
    this.parent.form.addControl(this.compType, this.formGroup);
  }

  toFormGroup(compFields: ComponentFieldModel<any>[]) {
    const group: any = {};
    compFields.forEach(field => {
      group[field.key] = new UntypedFormControl(field.key || '');
    });
    return new UntypedFormGroup(group);
  }

  getCompName(): string {
    return getPageCompTypeName(this.compType);
  }

  protected readonly types = ALL_COMP_TYPES;
}
