import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  constructor(private snackbar: MatSnackBar) {
  }

  showPopupMessage(message: string, type: string = '', quick: boolean = false) {
    let panelClass: string[] | undefined;
    type = type.toLowerCase();
    const types = ['error', 'warning', 'info', 'success'];
    if (!types.includes(type)) {
      type = 'info';
    }
    panelClass = ['popup', `popup-${type}`];
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: quick ? 2500 : 5000,
      panelClass
    };
    this.snackbar.open(message, undefined, config);
  }
}
