import {ConfigurableComponent} from '../../models';
import {PageCompType} from '../../enums';
import {ComponentConfiguration} from '../index';

export enum ClientConfigurationScheme {
  DEFAULT = 'default',
}

export class ClientConfiguration {
  id: number;
  name: string = '<no name>';
  version: number = 0;
  scheduledSyncHours: number = 0;
  scheme: string = ClientConfigurationScheme.DEFAULT;
  css?: string;
  defaultConfig: boolean = false;
  props: { [key: string]: string } = {};
  // Map from wrapper component type to component configuration
  _componentConfigurationMap = new Map<PageCompType | string, ComponentConfiguration<ConfigurableComponent>>();

  constructor(id: number, name: string, scheme: string) {
    this.id = id;
    this.name = name;
    this.scheme = scheme;
  }

  clone(): ClientConfiguration {
    const config = new ClientConfiguration(this.id, this.name, this.scheme);
    config.version = this.version;
    config.css = this.css;
    config.scheduledSyncHours = this.scheduledSyncHours;
    config.defaultConfig = this.defaultConfig;
    config.props = {...this.props};
    for (const [compType, compConfig] of this.componentConfigurationMap.entries()) {
      config.setConfiguration(compType, compConfig)
    }
    return config;
  }

  setConfiguration(compType: PageCompType | string, config: ComponentConfiguration<ConfigurableComponent>) {
    this._componentConfigurationMap.set(compType, config);
  }

  /**
   * Get configuration for component type. If no configuration is found, throw an error.
   * @param compType component type
   */
  getConfiguration(compType: PageCompType | string): ComponentConfiguration<ConfigurableComponent> {
    const config = this.getConfigurationSafe(compType);
    if (!config) {
      throw new Error('No configuration for component type ' + compType)
      // todo ej error, skapa ny config med första configurableComponent, ev i anropande component
    }
    return config;
  }

  /**
   * Get configuration for component type. If no configuration is found, return undefined.
   * @param compType component type
   */
  getConfigurationSafe(compType: PageCompType | string): ComponentConfiguration<ConfigurableComponent> | undefined {
    return this._componentConfigurationMap.get(compType);
  }

  get componentConfigurationMap(): Map<PageCompType | string, ComponentConfiguration<ConfigurableComponent>> {
    return this._componentConfigurationMap;
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      scheduledSyncHours: this.scheduledSyncHours,
      scheme: this.scheme,
      css: this.css,
      defaultConfig: this.defaultConfig,
      props: this.props,
      componentConfig: Object.assign({}, ...Array.from(this.componentConfigurationMap.entries())
        .map(([compType, compConfig]) => {
          const configValues = Array.from(compConfig.config.entries());
          const configObj = {
            componentType: compConfig.componentType,
            config: Object.assign({}, ...configValues.map(([k, v]) => ({[k]: v})))
          }
          return {[compType]: configObj};
        }))
    };
  }
}
