import {Injectable, Injector} from '@angular/core';
import {BaseService, RefLinkContentParser} from '@twpub/core/services';
import {catchError, Observable} from 'rxjs';
import {DisplayConcept, DisplayEntry, DisplayTerm, ImageData, VirtualFile} from '@twpub/core/models';

import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VirtualFileService extends BaseService {
  constructor(private injector: Injector, private parser: RefLinkContentParser) {
    super(injector);
  }

  public getVirtualFile(fileId: number): Observable<VirtualFile> {
    return this.http.get<VirtualFile>(this.url(`/vfiles/${fileId}`));
  }

  public getContentUrl(fileId: number, thumbnail: boolean = false) {
    return this.url(`/vfiles/${fileId}/` + (thumbnail ? 'thumbnail' : 'content'));
  }

  getImages(concept: DisplayConcept | undefined, term: DisplayTerm | undefined, imageFieldNames: string[] = []): number[] {
    return [...this.getImagesForEntry(term, imageFieldNames), ...this.getImagesForEntry(concept, imageFieldNames)];
  }

  private getImagesForEntry(entry: DisplayEntry | undefined, imageFieldNames: string[]): number[] {
    imageFieldNames = imageFieldNames.map(name => name.toLowerCase());
    const imageFields = entry?.fields
      .map(f => ({...f, label: f.label.toLowerCase()}))
      .filter(f => imageFieldNames.includes(f.label))
      .sort((a, b) => imageFieldNames.indexOf(a.label) - imageFieldNames.indexOf(b.label)
      ) || []
    const fileIds: number[] = [];
    for (const f of imageFields) {
      fileIds.push(...this.parser.getFileIds(f.refLinks || []));
    }
    return fileIds;
  }

  public loadImageData(fileId: number): Observable<ImageData> {
    return this.getVirtualFile(fileId).pipe(
      map(file => ({
        vfile: file,
        thumbUrl: this.getContentUrl(fileId, true),
        contentUrl: this.getContentUrl(fileId)
      }))
    );
  }

  static isImage = (vfile?: VirtualFile): boolean => VirtualFileService.isType('image/', vfile);
  static isAudio = (vfile?: VirtualFile): boolean => VirtualFileService.isType('audio/', vfile);
  static isVideo = (vfile?: VirtualFile): boolean => VirtualFileService.isType('video/', vfile);
  static isPdf = (vfile?: VirtualFile): boolean => VirtualFileService.isType('application/pdf', vfile);
  private static isType = (type: string, vfile?: VirtualFile) => vfile ? vfile.mimeType.startsWith(type) : false;
}
