import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BaseResultViewComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ConceptSelection, DisplayConcept, DisplayEntry, DisplayField, FieldProps, ResultView} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {ConceptService, FieldService, NavigationService, VirtualFileService} from '@twpub/core/services';

export interface Tile {
  color: string;
  cols: string;
  rows: number;
  text: string;
  tpl: TemplateRef<any>
}

@Component({
  selector: 'pub-card-result-view',
  templateUrl: './card-result-view.component.html',
  styleUrls: ['./card-result-view.component.scss']
})
export class CardResultViewComponent extends BaseResultViewComponent implements OnInit {
  static readonly compName = 'Card Result View';
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [];

    constructor() {
      super(CardResultViewComponent, 'CardResultViewComponent', CardResultViewComponent.compName, [PageCompType.ResultView]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultView>(CardResultViewComponent, this.name, initialConfig);
    }
  }

  @ViewChild('headerTemplate', {static: true}) headerTemplate!: TemplateRef<any>;
  @ViewChild('leftTemplate', {static: true}) leftTemplate!: TemplateRef<any>;
  @ViewChild('rightTemplate', {static: true}) rightTemplate!: TemplateRef<any>;

  tiles: Tile[] = [];
  fileIds: number[] = [];
  debug: boolean = false
  termNameField!: DisplayField;
  imageFieldNames: string[] = [];

  ngOnInit(): void {
    this.tiles = [
      {text: 'Header', cols: '1 / -1', rows: 1, color: 'lightblue', tpl: this.headerTemplate},
      {text: 'Left', cols: '1 / 2', rows: 1, color: 'lightpink', tpl: this.leftTemplate},
      {text: 'Right', cols: '2 / 3', rows: 1, color: 'lime', tpl: this.rightTemplate}
    ];
  }

  constructor(private injector: Injector, private virtualFileService: VirtualFileService,
              private fieldService: FieldService, private naviService: NavigationService, private conceptService: ConceptService) {
    super(injector);
  }

  override doUpdate() {
    super.doUpdate();
    const concept = this.concept;
    if (concept && !this.termId) {
      this.findFirstTermId(concept);
    }
    const term = this.getTerm();
    this.termNameField = {oid: 'term', label: 'Term', value: this.getTerm()?.name || ''};
    this.imageFieldNames = this.fieldService.getImageFields();
    this.fileIds = this.virtualFileService.getImages(concept, term, this.imageFieldNames);
    this.conceptFields = this.removeImageFields(this.concept);
  }

  private removeImageFields(entry: DisplayEntry | undefined) {
    const names = this.imageFieldNames.map(name => name.toLowerCase());
    return entry?.fields
      .map(f => ({...f, label: f.label.toLowerCase()}))
      .filter(f => !names.includes(f.label)) || [];
  }

  override selectConcept(selection: ConceptSelection) {
    const url = this.naviService.createUrl(undefined, selection.conceptId, selection.termId);
    this.naviService.go(url);
    this.sharedStateService.selectConcept(selection);
  }

  private findFirstTermId(concept: DisplayConcept) {
    const srcLangs = this.sessionObj.sourceLangs || [];
    this.conceptService.getTermsInLanguages(concept.id, srcLangs).subscribe(terms => {
      this.termId = terms[0]?.id;
    })
  }
}
