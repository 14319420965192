import {Component, Injector, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ClientProp, ResultListProp, TITLE} from '@twpub/core/constants';
import {FieldProps, ResultView} from '@twpub/core/models';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ClientConfigurationService, SessionService} from '@twpub/core/services';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {LanguageService} from '@twpub/core/services/language.service';
import {BaseResultViewComponent} from '@twpub/shared/components/base/base-result-view/base-result-view.component';
import {AfUiModule} from '../../af-ui.module';

const {IncludeSynonyms} = ResultListProp;

@Component({
  selector: 'pub-af-result-view',
  templateUrl: './af-result-view.component.html',
  styleUrls: ['./af-result-view.component.scss']
})
export class AfResultViewComponent extends BaseResultViewComponent implements OnInit {
  includeSynonyms?: boolean;

  private titleName: string = TITLE;

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [
      {
        key: IncludeSynonyms,
        label: 'Include synonym terms',
        controlType: ControlType.Boolean,
        description: 'Should a row with synonym terms be included in the result'
      }
    ];

    constructor() {
      super(AfResultViewComponent, 'AfResultViewComponent', 'AF Result View', [PageCompType.ResultView]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultView>(AfResultViewComponent, this.name, initialConfig);
    }
  }

  constructor(private injector: Injector, private titleService: Title, private configService: ClientConfigurationService,
              private langService: LanguageService) {
    super(injector);
  }

  ngOnInit(): void {
    this.includeSynonyms = this.config?.getBoolean(IncludeSynonyms);
    this.configService.getDefaultConfiguration(this.sessionObj.configId).subscribe({
      next: (config) => {
        this.titleName = config.props[ClientProp.TitleName] || TITLE;
      }
    });
    this.sourceLangCodes = this.sessionObj.sourceLangs;
    this.targetLangCodes = this.sessionObj.targetLangs;
  }

  override doUpdate(): void {
    super.doUpdate();
    this.logger.trace('AfResultViewComponent.doUpdate:', {concept: this.concept, termId: this.termId})
    const title = `${this.titleName} - ${this.getSelectedTermName()}`;
    this.titleService.setTitle(title);
  }

  getLangCode(code: string = ''): string {
    return this.langService.getIso2Code(code);
  }

  getSelectedTermName(): string {
    return this.getTerm(this.termId)?.name || '';
  }

  /*
    getTermFields(termId: number | undefined = undefined): DisplayField[] | undefined {
      const term = this.getTerm(termId);
      const entries = this.getFieldEntries(term);
      entries.unshift({oid: 'term', value: term?.name})
      entries.unshift({oid: 'lang', value: this.getLanguageName(term?.languageCode)})
      return entries;
    }
  */

  /*
    private getFieldEntries(entry: DisplayEntry | undefined) {
      return entry?.fields || [];
    }
  */

  getLanguageName(langCode?: string): string {
    return this.languages?.find((l) => l.code === (langCode || this.sourceLangCodes))?.name || '';
  }

  /**
   * Returns all term names that are in the same language as the focus term.
   */
  getSynonymTermNames(langCode?: string) {
    const terms = this.getSynonymTerms(langCode);
    return terms ? terms.map((t) => t.name).join() : '';
  }

  getTargetLanguageName(): string {
    return this.getLanguageName(this.targetLangCodes?.[0]);
  }

  getTargetTermNames(): string {
    return this.getTerms(this.targetLangCodes?.[0] || '')?.[0]?.name || '';
  }

  clearSearch() {
    this.sharedStateService.searchFor('');
  }
}
