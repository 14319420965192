import {Component, OnInit} from '@angular/core';
import {ClientConfigurationService} from '@twpub/core/services';
import {NGXLogger} from 'ngx-logger';
import {SynchronizeDictionariesTaskStatus} from '@twpub/core/models/synchronize-dictionaries-task-status';
import {ClientConfiguration} from '@twpub/core/utils';

@Component({
  selector: 'pub-scheduled-sync-settings',
  templateUrl: './scheduled-sync-settings.component.html',
  styleUrls: ['./scheduled-sync-settings.component.scss']
})
export class ScheduledSyncSettingsComponent implements OnInit {
  updateIntervals = [
    {value: 0, viewValue: 'No automatic updates'},
    {value: 1, viewValue: 'Every hour'},
    {value: 2, viewValue: 'Every 2 hours'},
    {value: 4, viewValue: 'Every 4 hours'},
    {value: 8, viewValue: 'Every 8 hours'},
    {value: 12, viewValue: 'Every 12 hours'},
    {value: 24, viewValue: 'Every day'},
    {value: 48, viewValue: 'Every 2 days'},
    {value: 168, viewValue: 'Once a week'}
  ];

  selectedInterval: number = 0;
  scheduledStatus?: SynchronizeDictionariesTaskStatus;

  private clientConfiguration?: ClientConfiguration

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService) {
  }

  ngOnInit(): void {
    this.configService.getDefaultConfiguration(undefined).subscribe({
      next: (config) => {
        this.clientConfiguration = config;
        this.selectedInterval = config.scheduledSyncHours;
      }
    });
    this.getScheduledStatus();
  }

  onSelectionChange(): void {
    if (this.clientConfiguration) {
      this.clientConfiguration.scheduledSyncHours = this.selectedInterval;
      this.configService.updateConfiguration(this.clientConfiguration).subscribe({next: () => this.getScheduledStatus()});
    }
  }

  private getScheduledStatus() {
    this.configService.getScheduledSyncStatus().subscribe({
      next: (status) => {
        this.scheduledStatus = status;
      }
    });
  }
}
