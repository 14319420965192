import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ComponentFieldModel, DropdownFieldModel} from '@twpub/core/models';
import {ControlType} from '@twpub/core/enums';

@Component({
  selector: 'pub-component-editor-field',
  templateUrl: './component-editor-field.component.html',
  styleUrls: ['./component-editor-field.component.scss']
})
export class ComponentEditorFieldComponent implements OnInit {
  @Input() fieldModel!: ComponentFieldModel<any>;
  @Input() form!: UntypedFormGroup;

  // eslint-disable-next-line no-undef
  fieldType = ControlType;
  fieldComp?: DropdownFieldModel;

  ngOnInit(): void {
    this.fieldComp = this.fieldModel;
    this.form.get(this.fieldComp.key)?.setValue(this.fieldComp.value);
  }
}
