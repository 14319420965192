import {
  EvaluationContext,
  Hook,
  JsonValue,
  Logger,
  OpenFeatureEventEmitter,
  Provider,
  ResolutionDetails
} from '@openfeature/web-sdk';
import {BaseService} from '@twpub/core/services/base.service';
import {Injectable, Injector} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureProvider extends BaseService implements Provider {
  readonly metadata = {
    name: 'Feature Provider',
  } as const;

  readonly runsOn = 'client';
  events = new OpenFeatureEventEmitter();
  hooks?: Hook[] | undefined;
  private featureFlags: Record<string, any> = {};

  constructor(private injector: Injector) {
    super(injector)
    this.loadFeatureFlags();
  }

  private loadFeatureFlags(): Observable<any> {
    return this.http.get(this.url('/feature-flags'));
  }

  onContextChange?(oldContext: EvaluationContext, newContext: EvaluationContext): void | Promise<void> {
    throw new Error('Method not implemented.');
  }

  public resolveBooleanEvaluation(flagKey: string, defaultValue: boolean, context: EvaluationContext, logger: Logger): ResolutionDetails<boolean> {
    const value = this.featureFlags[flagKey];
    return {
      value: typeof value === 'boolean' ? value : defaultValue,
      reason: 'default',
    };
  }

  resolveStringEvaluation(flagKey: string, defaultValue: string, context: EvaluationContext, logger: Logger): ResolutionDetails<string> {
    const value = this.featureFlags[flagKey];
    return {
      value: typeof value === 'string' ? value : defaultValue,
      reason: 'default',
    };
  }

  resolveNumberEvaluation(flagKey: string, defaultValue: number, context: EvaluationContext, logger: Logger): ResolutionDetails<number> {
    throw new Error('Method not implemented.');
  }

  resolveObjectEvaluation<T extends JsonValue>(flagKey: string, defaultValue: T, context: EvaluationContext, logger: Logger): ResolutionDetails<T> {
    const value = this.featureFlags[flagKey];
    return {
      value: typeof value === 'object' ? value : defaultValue,
      reason: 'default',
    };
  }

  async initialize?(context?: EvaluationContext | undefined): Promise<void> {
    try {
      const response = await this.loadFeatureFlags().toPromise();
      this.featureFlags = response;
    } catch (error) {
      this.logger.error('Error initializing feature flags:', error);
    }
  }
}
