import {Injectable} from '@angular/core';
import {RefLink} from '@twpub/core/models';
import {isArray, isNil} from 'lodash';
import {NGXLogger} from 'ngx-logger';

export interface ContentItem {
  index: number;
  content: string;
  link?: RefLink;
  start: number;
  end: number;
  isVirtualFile?: boolean;
  fileId?: number;
}

const termwebFilesPrefix = 'termweb://files/';

@Injectable()
export class RefLinkContentParser {
  constructor(private logger: NGXLogger) {
  }

  private getContentItem(item: ContentItem): ContentItem {
    return item;
  }

  parse(value?: string | undefined, refLinks?: RefLink[]): ContentItem[] {
    const preparedValue = this.prepareLinkValue(value);

    if (refLinks?.length) {
      const items: ContentItem[] = [];
      let index = 0;
      let position = 0;
      refLinks.forEach(rl => {
        if (position < rl.position) {
          const textValue: string = preparedValue.substring(position, rl.position);
          items.push(this.getContentItem({
            index: index++,
            content: textValue,
            start: position,
            end: rl.position
          }));
          position = rl.position;
        }

        const end = position + rl.text.length;
        const linkValue: string = preparedValue.substring(position, end);

        items.push(this.getContentItem({
          index: index++,
          link: rl,
          content: linkValue,
          start: position,
          end
        }));
        position = end;
      });

      if (position < preparedValue.length) {
        const textValue: string = preparedValue.substring(position, preparedValue.length);
        items.push(this.getContentItem({
          index: index++,
          content: textValue,
          start: position,
          end: preparedValue.length
        }));
      }
      return items;
    } else {
      return [this.getContentItem({
        index: 0,
        content: preparedValue,
        start: 0,
        end: preparedValue.length
      })];
    }
  }

  private prepareLinkValue(value: string | undefined) {
    let preparedValue = isNil(value) ? null : value;
    if (isArray(preparedValue)) {
      // FIXME for some reason sometimes it's an array
      preparedValue = preparedValue[0];
      this.logger.debug('prepareLinkValue: value is array', value);
    }
    return preparedValue || '';
  }

  isVirtualFile(item: ContentItem): boolean {
    return !!item.link?.url?.startsWith(termwebFilesPrefix);
  }

  getFileId(item: ContentItem): number {
    const link = item.link;
    return this.doGetFileId(link);
  }

  private doGetFileId(link: RefLink | undefined) {
    const idNum = parseInt(link?.url?.substring(termwebFilesPrefix.length) || '', 10);
    return Number.isNaN(idNum) ? 0 : idNum;
  }

  getFileIds(links: RefLink[]): number[] {
    return links.map(link => this.doGetFileId(link));
  }
}
