<div class="container p-4">
  <div class="header-container">
    <h4>Languages</h4>
  </div>
  <table mat-table [dataSource]="languages" class="mat-elevation-z8">
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Code</th>
      <td mat-cell *matCellDef="let lang"> {{lang.code}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let lang"> {{lang.name}} </td>
    </ng-container>

    <ng-container matColumnDef="terms">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef> Terms</th>
      <td mat-cell class="cell-numeric" *matCellDef="let lang"> {{lang.numberOfTerms}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Visibility</th>
      <td mat-cell *matCellDef="let lang">
        <pub-icon-button [tooltip]="lang.visible ? 'Visible':'Hidden'" (buttonClick)="changeVisibility(lang.id)"
                         color="primary" [withText]="false"
                         [icon]="lang.visible ? 'visibility' : 'visibility_off'"></pub-icon-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="!row?.visible?'row-disabled':''">
    </tr>
  </table>
</div>
