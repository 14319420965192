import {SessionObject} from '@twpub/core/models/session-object';

export class SearchCriteria {
  dictIds: number[];
  sectionOids: string[];
  domainIds: number[];
  sourceLangCodes: string[];
  targetLangCodes: string[];
  searchStr: string;
  conceptFieldNames: string[];
  termFieldNames: string[];

  constructor(sessionObj: SessionObject) {
    this.dictIds = [sessionObj.dictId || -1];
    this.sectionOids = sessionObj.sectionOids || []
    this.domainIds = sessionObj.domainIds || []
    this.sourceLangCodes = sessionObj.sourceLangs || [];
    this.targetLangCodes = sessionObj.targetLangs || [];
    this.searchStr = sessionObj.searchTerm || '';
    this.conceptFieldNames = [];
    this.termFieldNames = [];
  }
}
