import {Component, Input, OnInit} from '@angular/core';
import {DictionaryStatistics} from '@twpub/core/models/dictionary-statistics';
import {DictionaryService} from '@twpub/core/services';
import {Section} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'pub-sections-panel',
  templateUrl: './sections-panel.component.html',
  styleUrls: ['./sections-panel.component.scss']
})
export class SectionsPanelComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'concepts', 'actions'];
  @Input() dictStat!: DictionaryStatistics;
  sections: Section[] = [];

  constructor(private logger: NGXLogger, private dictService: DictionaryService) {
  }

  ngOnInit(): void {
    this.dictService.getAllSections(this.dictStat.id).subscribe((sections) => {
      this.sections = sections;
      this.logger.debug('SectionsPanelComponent.oninit: ', {sections})
    });
  }

  changeVisibility(sectionId: number) {
    const oldSection = this.sections.find(s => s.id === sectionId);
    if (oldSection) {
      const section: Section = {
        ...oldSection,
        visible: !oldSection.visible
      };
      this.dictService.updateSection(section).subscribe(() => {
        oldSection.visible = section.visible;
      });
    }
  }
}
