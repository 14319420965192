import {Component, Injector, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ResultListProp, SearchResultConfig} from '@twpub/core/constants';
import {ConceptService, DictionaryService, SearchService, SharedStateService} from '@twpub/core/services';
import {ConceptSelection, Language, PaginationData, SearchCriteria, TermSearchResult} from '@twpub/core/models';
import {fromPageEvent, hasSessionChanges} from '@twpub/core/utils';
import {SessionParam} from '@twpub/core/enums';
import {NavigationService} from '@twpub/core/services/navigation.service';

@Component({
  template: ''
})
export abstract class BaseResultListComponent extends BaseConfigurableComponent {
  protected searchService: SearchService;
  protected dictService: DictionaryService
  protected conceptService: ConceptService;
  protected naviService: NavigationService;
  protected sharedStateService: SharedStateService;
  resultPaginator!: PaginationData;
  results?: TermSearchResult[];

  dataSource: MatTableDataSource<TermSearchResult> = new MatTableDataSource();
  isSearching = false;
  isWaiting = false;
  searchConfig!: SearchResultConfig
  includeSynonyms = false; // endast AF
  fieldNames: string[] = []
  protected languages: Language[] = [];
  protected dictKey: string = '';

  /**
   * Explicitly casts the type of the result object to avoid "Unresolved variable" errors in template.
   * @param res - TermSearchResult object from MatTableDataSource
   */
  toRes = (res: any): TermSearchResult => res as TermSearchResult;

  sourceLangCodes: string[] = [];

  hasMultipleSourceLangs = () => this.sourceLangCodes.length !== 1;

  isSourceLang = (code: string) => this.sourceLangCodes.includes(code);

  protected constructor(private injectorObj: Injector) {
    super();
    this.naviService = injectorObj.get(NavigationService);
    this.searchService = injectorObj.get(SearchService);
    this.dictService = injectorObj.get(DictionaryService);
    this.conceptService = injectorObj.get(ConceptService);
    this.sharedStateService = injectorObj.get(SharedStateService);
  }

  protected performSearch(pData?: PaginationData, clear: boolean = false, callbackFn?: (resultIndex?: number) => void) {
    const {sourceLangs, dictId} = this.sessionObj;
    if (this.shouldPerformSearch() && sourceLangs && dictId) {
      const searchCriteria = new SearchCriteria(this.sessionObj);
      this.searchService.search(searchCriteria, this.searchConfig, pData).subscribe({
        next: (pagedResult) => {
          if (clear || this.results === undefined) {
            this.results = [];
          }
          this.results.push(...pagedResult.results);
          this.dataSource.data = this.results; // todo move to child class. Only relevant for mat-table
          this.logger.debug('BaseResultListComponent.next:', {results: this.results})
          this.sessionObj.resultPaginator = fromPageEvent({
            pageSize: pagedResult.pageSize,
            pageIndex: pData?.pageIndex || 0,
            previousPageIndex: pData?.previousPageIndex || 0,
            length: pagedResult.total
          });
          this.isSearching = true;
          this.resultPaginator = this.sessionObj.resultPaginator;
          this.sharedStateService.setPaginationData(this.sessionObj.resultPaginator);
          if (callbackFn) {
            callbackFn(this.sessionObj.resultIndex);
          }
          this.isWaiting = false
        }
      });
    } else {
      this.isWaiting = false;
    }
  }

  private shouldPerformSearch() {
    const initialSearch = this.config.getBoolean(ResultListProp.InitialSearch);
    const searchTerm = this.sessionObj.searchTerm;
    return initialSearch || searchTerm?.length;
  }

  protected onInit() {
    this.isWaiting = true;
    const includeSynonyms = this.config.getBoolean(ResultListProp.IncludeSynonyms);
    const initialSearch = this.config.getBoolean(ResultListProp.InitialSearch);
    const fieldNames = this.config.getString(ResultListProp.Fields);
    this.searchConfig = {
      [ResultListProp.IncludeSynonyms]: includeSynonyms,
      [ResultListProp.InitialSearch]: initialSearch,
      [ResultListProp.Fields]: fieldNames
    }

    this.includeSynonyms = includeSynonyms;
    this.fieldNames = fieldNames?.split(';') || [];
  }

  protected fetchLangNames(callback?: (langs: Language[]) => void) {
    const dictId = this.sessionObj.dictId;
    if (dictId) {
      this.dictService.getLanguages(dictId).subscribe({
        next: (langs) => {
          this.languages = langs;
          this.sourceLangCodes = this.sessionObj.sourceLangs || [];
          callback?.(langs);
        }
      });
      this.dictService.getDictionary(dictId).subscribe({
        next: (dict) => {
          this.dictKey = dict.key;
        }
      });
    }
  }

  override doUpdate(changes?: SimpleChanges): void {
    super.doUpdate();
    if (changes) {
      const {
        SEARCH_TERM,
        SOURCE_LANGS,
        DICT_ID,
        TARGET_LANGS,
        ADDITIONAL_FIELD,
        SECTION_OIDS,
        DOMAIN_IDS
      } = SessionParam;
      if (hasSessionChanges(changes, [SOURCE_LANGS, SEARCH_TERM, DICT_ID, TARGET_LANGS, ADDITIONAL_FIELD, SECTION_OIDS, DOMAIN_IDS])) {
        this.isWaiting = true;
        if (hasSessionChanges(changes, [DICT_ID, SOURCE_LANGS, TARGET_LANGS])) {
          this.fetchLangNames();
        }
        if (hasSessionChanges(changes, [SOURCE_LANGS, SEARCH_TERM, DICT_ID, SECTION_OIDS, DOMAIN_IDS])) {
          this.sessionObj.resultPaginator.pageIndex = 0;
          this.sessionObj.resultPaginator.totalItems = -1;
        }
        this.performSearch(this.sessionObj.resultPaginator, true);
      }
    }
  }

  hasNoResults() {
    return !this.dataSource.data?.length && this.sessionObj.searchTerm?.length
  }

  resultClick(res: TermSearchResult, index?: number, skipUrl?: boolean) {
    const conceptId = res.term.conceptId;
    const termId = res.term.id;
    this.logger.debug('BaseResultListComponent.resultClick:----------------------', {conceptId, termId})

    if (!skipUrl) {
      const url = this.naviService.createUrl(this.sessionObj.dictId, conceptId, termId);
      this.naviService.replaceState(url);
    }
    this.sharedStateService.selectConcept({conceptId, termId, index});
  }

  selectConceptFromView(selection: ConceptSelection) {
    this.logger.trace('BaseResultListComponent.selectConceptFromView: ', {selection})
    this.sharedStateService.selectConcept(selection);
  }
}
