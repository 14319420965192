import {Component, Inject} from '@angular/core';
import {DictionaryStatistics} from '@twpub/core/models/dictionary-statistics';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {DatePipe} from '@angular/common';
import {timeStr} from '@twpub/core/utils';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {DictionaryService} from '@twpub/core/services';

@Component({
  selector: 'pub-dictionary-details',
  templateUrl: './dictionary-details.component.html',
  styleUrls: ['./dictionary-details.component.scss']
})
export class DictionaryDetailsComponent {
  dictStat: DictionaryStatistics;

  constructor(private logger: NGXLogger, private dictService: DictionaryService, private dialog: MatDialog,
              public dialogRef: MatDialogRef<DictionaryDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { dictStat: DictionaryStatistics },
              private datePipe: DatePipe) {
    this.dictStat = data.dictStat;
  }

  isComplete() {
    const status = this.dictStat.syncStats.status;
    return status === 'COMPLETE' || status === 'WARNING';
  }

  getStatusMessage(): string {
    const syncStats = this.dictStat.syncStats;
    let msg = ''
    if (this.isComplete()) {
      msg += this.dictStat.publishedConceptCount + ' concepts';
      const dateStr = this.datePipe.transform(syncStats.lastSyncDate, 'yyyy-MM-dd HH:mm:ss');
      msg += ', last synchronized: ' + dateStr + ' (' + timeStr(syncStats.lastSyncTimeMs) + ')';
      if (syncStats.lastSyncTimeMs !== syncStats.initialSyncTimeMs) {
        msg += ', initial synchronization time: ' + timeStr(syncStats.initialSyncTimeMs);
      }
      return msg;
    }
    return 'Not synchronized';
  }

  deleteIndexPrompt() {
    const dictId = this.dictStat.id;
    const startMs = Date.now();
    const message: string[] = ['Are you sure you want to delete all synchronized data for "' + this.dictStat.name + '"?'];
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {data: {message}});

    confirmDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.dictStat.syncStats.status = 'IN_PROGRESS';
        this.dictService.deleteIndex(dictId).subscribe({
          next: () => {
            this.dictStat.syncStats.status = 'IDLE';
            const timeMs = Date.now() - startMs;
            this.logger.debug('Deleted index for dictionary ' + dictId + ' in ' + timeMs + ' ms');
            this.dialogRef.close();
          }
        })
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
