import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {PaginationData} from '@twpub/core/models';
import {fromPageEvent} from '@twpub/core/utils';

@Component({
  selector: 'pub-af-result-pagination',
  templateUrl: './af-result-pagination.component.html',
  styleUrls: ['./af-result-pagination.component.scss']
})
export class AfResultPaginationComponent implements OnInit, OnChanges {
  @Input() pgData!: PaginationData;
  @Input() resultName?: string = '';
  @Output() pgEvent = new EventEmitter<PaginationData>()

  totalPages?: number;
  activePage?: number;
  totalResults?: number;
  resultStart?: number;
  resultEnd?: number;

  constructor(private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.updateValues();
  }

  private updateValues() {
    this.totalPages = this.pgData.totalPages;
    this.activePage = this.pgData.pageIndex + 1;
    this.totalResults = this.pgData.totalItems === -1 ? 0 : this.pgData.totalItems;
    this.resultStart = this.pgData.itemsStartIndex;
    this.resultEnd = this.pgData.itemsEndIndex;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('AfResultPaginationComponent.ngOnChanges: ', {changes})
    this.updateValues();
  }

  pageChange(event: any) {
    this.logger.debug('AfResultPaginationComponent.pageChange: ', {event})
    const {pageIndex, pageSize, totalItems} = this.pgData;
    const newPgData = fromPageEvent({
      pageIndex: event.detail - 1, // Af pagination index starts from 1
      previousPageIndex: pageIndex,
      pageSize,
      length: totalItems
    });
    this.pgEvent.emit(newPgData);
  }
}
