import {ConceptSelection, DisplayConcept, DisplayField, DisplayTerm, Language, ResultView} from '@twpub/core/models';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {SharedStateService} from '@twpub/core/services';

@Component({
  template: ''
})
export abstract class BaseResultViewComponent extends BaseConfigurableComponent implements ResultView {
  @Input() concept?: DisplayConcept;
  @Input() termId?: number;
  @Input() languages: Language[] = [];

  @Output() conceptSelected = new EventEmitter<ConceptSelection>()

  sourceLangCodes?: string[];
  targetLangCodes?: string[];
  focusLangCode?: string;

  protected sharedStateService: SharedStateService;

  classificationFields: DisplayField[] = [];
  conceptFields: DisplayField[] = [];

  constructor(injector: Injector) {
    super();
    this.sharedStateService = injector.get(SharedStateService);
  }

  override doUpdate(): void {
    super.doUpdate()
    const term = this.getTerm(this.termId);
    this.sourceLangCodes = term ? [term.languageCode] : this.sessionObj.sourceLangs;
    this.targetLangCodes = this.sessionObj.targetLangs;
    this.classificationFields = this.concept ? [this.concept.section] : [];
    this.conceptFields = this.concept?.fields || [];
  }

  /**
   * Returns the term with specified id, or the selected term if no id is specified.
   * @param termId The term id.
   * @protected
   */
  protected getTerm(termId?: number): DisplayTerm | undefined {
    return this.concept?.terms.find((t) => t.id === (termId || this.termId));
  }

  getLanguageField(langCode?: string): DisplayField {
    if (!this.languages?.length) throw new Error('No languages in component');
    const lang = this.languages.find((l) => l.code === (langCode || this.focusLangCode));
    return {oid: 'lang', label: 'Language', value: lang?.name || ''}
  }

  /**
   * Returns all terms in specified language.
   * @param langCode The code.
   */
  getTerms(langCode: string) {
    return this.concept?.terms.filter((t) => t.languageCode === langCode)
  }

  getSynonymTerms(langCode?: string) {
    return this.concept?.terms.filter((t) => t.languageCode === langCode && t.id !== this.termId)
  }

  /**
   * Returns an array of target languages in the concept. Either only the selected target language(s), or languages for
   * all terms, except the selected term's langauge.
   */
  getTargetLanguages(): string[] {
    let pred: (t: DisplayTerm) => boolean;
    if (this.targetLangCodes?.length) {
      pred = (t) => this.isTargetLanguage(t.languageCode) && !this.isSourceLanguage(t.languageCode)
    } else {
      pred = (t) => !this.isSourceLanguage(t.languageCode);
    }
    const allCodes = this.concept?.terms.filter(pred).map((t) => t.languageCode) || [];
    return [...new Set(allCodes)];
  }

  private isSourceLanguage = (langCode: string) => !!this.sourceLangCodes?.some(source => source === langCode);
  private isTargetLanguage = (langCode: string) => !!this.targetLangCodes?.some(code => code === langCode);

  selectConcept(selection: ConceptSelection) {
    this.conceptSelected.emit(selection);
  }

  selectDomains(domains: number[]) {
    this.sharedStateService.selectDomains(domains);
  }

  hasTermInLanguage(langCode: string | undefined) {
    return langCode && this.concept?.terms.some((t) => t.languageCode === langCode)
  }
}
