import {Component, Injector, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DictionarySelectorDirective} from './dictionary-selector.directive';
import {Dictionary, DictionarySelector} from '@twpub/core/models';
import {DictionaryService} from '@twpub/core/services';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-dictionary-wrapper',
  templateUrl: './dictionary-wrapper.component.html',
  styleUrls: ['./dictionary-wrapper.component.scss']
})
export class DictionaryWrapperComponent extends BaseWrapperComponent<DictionarySelector> implements OnChanges {
  override type = PageCompType.DictionarySelector;
  dictionaries?: Dictionary[];

  @ViewChild(DictionarySelectorDirective, {static: true}) pubDictSelect!: DictionarySelectorDirective

  constructor(private injector: Injector, private dictService: DictionaryService) {
    super(injector);
  }

  override init(): void {
    this.dictService.getDictionaries(true).subscribe({
      next: (dicts) => {
        const session = this.sessionObj;
        this.dictionaries = dicts;
        if (!this.dictService.exists(session.dictId, dicts)) {
          this.selectFirstDictionary(dicts);
        }
        this.update();
      }
    })
  }

  private selectFirstDictionary(dicts: Dictionary[]) {
    const dictId = dicts[0]?.id;
    this.sessionObj.dictId = dictId;
    this.sharedStateService.selectDictionary(dictId || 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.DICT_ID])) {
      this.logger.debug('DictionaryWrapperComponent.ngOnChanges:  ', {changes})
      this.update();
    }
  }

  getViewContainerRef() {
    return this.pubDictSelect.viewContainerRef;
  }

  override createComponent(): DictionarySelector {
    const comp = super.createComponent();
    comp.selectDictionary.subscribe((dictId: number) => this.sharedStateService.selectDictionary(dictId));
    super.update();
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentDictionary = this.sessionObj.dictId;
      comp.dictionaries = this.dictionaries
    })
  }
}
