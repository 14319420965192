import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {
  ClientConfigurationScheme,
  ComponentConfiguration,
  ComponentInit,
  fromPageEvent,
  InitialConfig
} from '@twpub/core/utils';
import {FieldProps, ResultList} from '@twpub/core/models';
import {ResultListProp} from '@twpub/core/constants';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {BaseResultListComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-search-result-list',
  templateUrl: './default-result-list.component.html',
  styleUrls: ['./default-result-list.component.scss']
})
export class DefaultResultListComponent extends BaseResultListComponent implements ResultList, OnInit {
  @Input() selectedLang?: string;
  pageSizeOptions = [10, 25, 50];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  static readonly compName = 'DefaultResultListComponent';

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [
      {
        key: ResultListProp.InitialSearch,
        label: 'Results visible on page load',
        controlType: ControlType.Boolean,
        description: 'Automatically list all terms when page is opened, before any search'
      }
    ];

    constructor() {
      // eslint-disable-next-line no-undef
      super(DefaultResultListComponent, DefaultResultListComponent.compName, 'Default Result List', [PageCompType.ResultList]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultList>(DefaultResultListComponent, this.name, initialConfig);
    }
  }

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.onInit();
    this.fetchLangNames();
    this.performSearch(this.sessionObj.resultPaginator, true);
  }

  pageChange(event: PageEvent) {
    this.performSearch(fromPageEvent(event), true);
  }
}
