import {Component, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {SearchFieldDirective} from './search-field.directive';
import {SearchField} from '@twpub/core/models';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-search-field-wrapper',
  templateUrl: './search-field-wrapper.component.html',
  styleUrls: ['./search-field-wrapper.component.scss']
})
export class SearchFieldWrapperComponent extends BaseWrapperComponent<SearchField> implements OnChanges {
  override type = PageCompType.SearchField;

  @ViewChild(SearchFieldDirective, {static: true}) pubSearchField!: SearchFieldDirective

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.SEARCH_TERM])) {
      this.update();
    }
  }

  override createComponent(): SearchField {
    const comp = super.createComponent();
    comp.searchFor.subscribe((str: string) => this.sharedStateService.searchFor(str))
    this.update();
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.searchTerm = this.sharedStateService.getSearchTerm();
    })
  }

  getViewContainerRef() {
    return this.pubSearchField.viewContainerRef;
  }
}
