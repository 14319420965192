import {Component, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientConfigurationService} from '@twpub/core/services';
import {TITLE} from '@twpub/core/constants';
import {environment} from '../../../../environments/environment';
import {KeycloakService} from 'keycloak-angular';
import {from} from 'rxjs';

@Component({
  selector: 'pub-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  protected readonly title = TITLE;
  protected readonly versionStr = 'v' + environment.appVersion + '--' + environment.buildNumber
  showMenu: boolean = false;

  constructor(private logger: NGXLogger,
              private configService: ClientConfigurationService, private kcService: KeycloakService) {
  }

  ngOnInit() {
    // Remove any css that has been added by search-page
    const style = document.getElementById('clientConfigCss');
    if (style) {
      style.parentElement?.removeChild(style);
    }
    this.checkTermWebConfiguration();
  }

  private checkTermWebConfiguration() {
    this.configService.hasTermWebConfiguration().subscribe({
      next: () => {
        from(this.kcService.isLoggedIn()).subscribe((isAuthenticated) => {
          this.showMenu = isAuthenticated
        });
      },
      error: () => {
        this.showMenu = false;
      }
    });
  }

  async logoutUser() {
    await this.kcService.logout(window.location.origin);
  }
}
