import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DomainPath} from '@twpub/core/models';

@Component({
  selector: 'pub-domain-breadcrumbs',
  templateUrl: './domain-breadcrumbs.component.html',
  styleUrls: ['./domain-breadcrumbs.component.scss']
})
export class DomainBreadcrumbsComponent {
  @Input() path?: DomainPath = {path: []};
  @Output() selectDomains = new EventEmitter<number[]>()

  onSelect(selValue?: string) {
    const selectedIds = selValue ? [Number.parseInt(selValue)] : [];
    this.selectDomains.emit(selectedIds);
  }
}
