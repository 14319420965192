import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {catchError} from 'rxjs/operators';

const serverName = `TermWebPublish Server`;
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private logger: NGXLogger) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error, req))
    );
  }

  private handleError(error: HttpErrorResponse, req: HttpRequest<any>): Observable<never> {
    let userMessage = '';
    const errorContext = `${req.method} ${req.urlWithParams}`;

    if (error.status === 0) {
      userMessage = `Unable to connect to ${serverName}.`;
    } else if (error.status === 401) {
      this.logger.info(`User session has timed out.`);
      this.router.navigate(['/admin']);
      return of();
    } else if (error.status === 404) {
      userMessage = `Resource not found.`;
    } else if (error.status === 500) {
      userMessage = `Internal error in ${serverName}.`;
    } else {
      userMessage = `Unknown error when connecting to ${serverName}. ${JSON.stringify(error)}`;
    }
    this.logger.error(`Error (${error.status}) when accessing ${errorContext}: ${userMessage}`);
    return of();
  }
}
