import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ConceptRelationService} from '@twpub/core/services/concept-relation.service';
import {ConceptRelation} from '@twpub/core/models';

@Component({
  selector: 'pub-render-relations',
  templateUrl: './render-relations.component.html',
  styleUrls: ['./render-relations.component.scss']
})
export class RenderRelationsComponent implements OnInit, OnChanges {
  @Input() conceptId?: number;
  relations: ConceptRelation[] = [];

  constructor(private relationService: ConceptRelationService) {
  }

  ngOnInit(): void {
    this.initRelations();
  }

  ngOnChanges(): void {
    this.initRelations()
  }

  isReversed = (relation: ConceptRelation): boolean => relation.targetConceptId === this.conceptId;

  private initRelations() {
    if (this.conceptId) {
      this.relationService.getRelationsForConcept(this.conceptId).subscribe(relations => {
        this.relations = relations;
      });
    } else {
      this.relations = []
    }
  }
}
