import {Type} from '@angular/core';
import _ from 'lodash';
import {ConfigurableComponent} from '../../models';
import {CompProp} from '../../constants';

type configValue = string | boolean | number | undefined;

export class ComponentConfiguration<C extends ConfigurableComponent> {
  component!: Type<C>; // Angular component to display
  componentType: string = ''; // Name of component stored on server

  config = new Map<string, configValue>()

  constructor(component: Type<C>, name: string, initialConfig: Map<string, configValue> | undefined = undefined) {
    this.component = component;
    this.componentType = name;
    if (initialConfig) {
      this.config = new Map(initialConfig);
    }
  }

  dump(): string {
    return [...this.config.entries()].toString();
  }

  getConfigValue(key: string, type: Type<Number | String | Boolean> | undefined = undefined): configValue {
    if (type === Boolean) return this.getBoolean(key);
    if (type === String) return this.getString(key);
    if (type === Number) return this.getNumber(key);
    return this.config.get(key);
  }

  getConfigValueAsString(key: string): string | undefined {
    return this.config.get(key)?.toString();
  }

  getBoolean(key: string, defaultValue?: boolean): boolean {
    const strVal = this.getConfigValueAsString(key);
    return strVal === 'true' || (strVal ? false : (defaultValue || false));
  }

  getString(key: string, defaultValue?: string): string | undefined {
    const strVal = this.getConfigValueAsString(key);
    return strVal || defaultValue;
  }

  getNumber(key: string, defaultValue?: number): number | undefined {
    const val = this.config.get(key);
    if (_.isNumber(val)) {
      return val;
    }
    if (_.isString(val)) {
      const num = _.parseInt(val);
      return _.isNaN(num) ? defaultValue : num;
    }
    return defaultValue;
  }

  setConfigValue(key: string, value: configValue): void {
    this.config.set(key, value);
  }

  /**
   * Syntactic sugar for getting visible property
   */
  isVisible(): boolean {
    return this.getBoolean(CompProp.Visible);
  }

  /**
   * Syntactic sugar for getting label property
   */
  getLabel(): string | undefined {
    return this.getString(CompProp.Label);
  }
}
