<div class="container" *ngIf="editConfiguration">
  <form [formGroup]="form">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Edit configuration</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-sm">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput class="form-control" formControlName="name" autocomplete="off"/>
              <mat-error *ngIf="hasError('name','empty')">Name cannot be empty</mat-error>
              <mat-error *ngIf="hasError('name', 'nameExists')">Name already exists</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field [style.width.%]="100">
              <mat-label>CSS</mat-label>
              <textarea matInput class="form-control" formControlName="css" rows="6"></textarea>
            </mat-form-field>
          </div>
        </div>
        <h6>Components</h6>
        <mat-accordion multi>
          <pub-component-editors-list [configuration]="editConfiguration"></pub-component-editors-list>
        </mat-accordion>
      </mat-card-content>

      <mat-card-actions>
        <div class="row">
          <div class="col-sm buttons-panel">
            <button mat-raised-button color="primary" (click)="saveEditConfiguration()" [disabled]="!form.valid">Apply
            </button>
            <button mat-raised-button color="primary" (click)="saveEditConfiguration(true)" [disabled]="!form.valid">
              {{ isEditing() ? 'Update' : 'Create' }} &amp; Close
            </button>
            <button mat-raised-button (click)="cancelEdit()">Cancel</button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
