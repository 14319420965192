import {Component} from '@angular/core';
import {ClientConfiguration} from '@twpub/core/utils';

@Component({
  selector: 'pub-configurations-panel',
  templateUrl: './configurations-panel.component.html',
  styleUrls: ['./configurations-panel.component.scss']
})
export class ConfigurationsPanelComponent {
  private _editConfiguration?: ClientConfiguration;
  get editConfiguration(): ClientConfiguration | undefined {
    return this._editConfiguration;
  }

  set editConfiguration(value: ClientConfiguration | undefined) {
    this._editConfiguration = value?.clone();
  }

  editingDone() {
    this._editConfiguration = undefined;
  }
}
