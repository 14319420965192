import {HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export function toHttpParams(params: { [key: string]: string | number | boolean }): HttpParams {
  return Object.getOwnPropertyNames(params)
    .reduce((p, key) => p.set(key, params[key]), new HttpParams());
}

export function url(uri: string): string {
  const base = (environment.serverUrl || '').replace(/\/+$/, ''); // remove trailing slashes
  const path = uri.replace(/^\/+/, ''); // remove leading slashes
  return base + '/' + path;
}
