<mat-card (click)="clickRelation()">
  <mat-card-header>
    <mat-card-title>{{ targetTermName }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <pub-card-content [conceptId]="targetConceptId" [termId]="targetTermId" [imageFields]="imageFields">
    </pub-card-content>
  </mat-card-content>
</mat-card>


<!--<a class="t" (click)="clickRelation(relation)">
  <span>{{ relation.type }}</span>
  <span [ngClass]="getClassForRelAttr(relation.attribute)"></span>
  <span [attr.title]="relation.text">{{ targetTermName }}</span>
</a>-->
