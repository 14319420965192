<!--<ng-container class="container">-->
<ng-container *ngIf="visible" class="container">
  <div class="language-radio-div {{cssClassName}}">
    <label>{{label}}</label>
    <mat-button-toggle-group appearance="standard" [value]="currentLanguages?.[0]" (change)="onSelect($event.value)">
      <ng-container *ngFor="let lang of (langs.slice(0,5))">
        <mat-button-toggle *ngIf="lang.code !== ''" [value]="lang.code">{{getLanguageName(lang)}}</mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
    <ng-container *ngIf="langs.length > 5">
      <button class="more-button" mat-button [matMenuTriggerFor]="menu" matTooltip="More languages"><mat-icon>more_vert</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let lang of (langs.slice(5))"
                (click)="onSelect(lang.code)">
          <mat-icon><ng-container *ngIf="isLanguageSelected(lang.code)">check</ng-container></mat-icon>
          {{getLanguageName(lang)}}</button>
      </mat-menu>
    </ng-container>
  </div>
</ng-container>

