import {Component} from '@angular/core';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, ResultView} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {BaseResultViewComponent} from '@twpub/shared/components/base/base-result-view/base-result-view.component';

@Component({
  selector: 'pub-default-result-view',
  templateUrl: './default-result-view.component.html',
  styleUrls: ['./default-result-view.component.scss']
})
export class DefaultResultViewComponent extends BaseResultViewComponent {
  static readonly compName = 'Default Result View';
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [];

    constructor() {
      super(DefaultResultViewComponent, 'DefaultResultViewComponent', DefaultResultViewComponent.compName, [PageCompType.ResultView]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultView>(DefaultResultViewComponent, this.name, initialConfig);
    }
  }

  hasSelectedTerm = () => this.concept?.terms.find(t => t.id === this.termId);
}
