import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {VirtualFileService} from '@twpub/core/services/virtual-file.service';
import {NGXLogger} from 'ngx-logger';
import {GalleryComponent, GalleryItem, GalleryModule, ImageItem} from 'ng-gallery';
import {ImageData} from '@twpub/core/models/image-data';
import {forkJoin, Observable} from 'rxjs';
import {NgIf} from '@angular/common';
import {NoImageComponent} from '../card-image/no-image/no-image.component';
import {hasPropChanges} from '@twpub/core/utils';

@Component({
  selector: 'pub-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],

  standalone: true,
  imports: [GalleryModule, NgIf, NoImageComponent]
})
export class ImageGalleryComponent implements OnChanges {
  @Input() entryId?: number = 0;
  @Input() fileIds: number[] = [];
  images: GalleryItem[] = [];
  loading: boolean = true;

  @ViewChild(GalleryComponent) gallery!: GalleryComponent;

  constructor(private logger: NGXLogger, private fileService: VirtualFileService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasPropChanges(changes, ['entryId', 'fileIds'])) {
      this.loadImages();
    }
  }

  private loadImages() {
    this.logger.debug('ImageGalleryComponent.loadImages:', {fileIds: this.fileIds});
    const imageRequests: Observable<ImageData>[] = this.fileIds.map(fileId =>
      this.fileService.loadImageData(fileId));

    forkJoin(imageRequests).subscribe({
      next: (imageDatas) => {
        this.images = imageDatas.map(imageData =>
          new ImageItem({src: imageData.contentUrl, thumb: imageData.thumbUrl})
        );
      },
      error: (error) => {
        this.logger.error('Failed to load images', error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
}
