import {APP_INITIALIZER, NgModule} from '@angular/core';
import {InitService} from '@twpub/core/services';
import {KeycloakAngularModule} from 'keycloak-angular';

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: InitService) => () => initService.initApp(),
      deps: [InitService],
      multi: true
    }
  ]
})
export class InitModule {
}
