import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'pub-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnChanges {
  @Input() public icon: string = 'error';
  @Input() public hoverIcon?: string;
  @Input() public tooltip: string = '';
  @Input() public position: TooltipPosition = 'right';
  @Input() public color: ThemePalette = 'primary';
  @Input() public withText: boolean = false;
  @Input() public disabled: boolean = false;
  @Output() public buttonClick: EventEmitter<any> = new EventEmitter();

  currentIcon: string = this.icon;

  ngOnChanges() {
    this.currentIcon = this.icon;
  }

  onClick() {
    this.buttonClick.emit();
  }

  onMouseEnter() {
    if (this.hoverIcon) {
      this.currentIcon = this.hoverIcon;
    }
  }

  onMouseLeave() {
    this.currentIcon = this.icon;
  }
}
