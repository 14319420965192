export enum SessionParam {
  SOURCE_LANGS = 'sourceLangs',
  TARGET_LANGS = 'targetLangs',
  SEARCH_TERM = 'searchTerm',
  CONCEPT_ID = 'conceptId',
  TERM_ID = 'termId',
  DICT_ID = 'dictId',
  SECTION_OIDS = 'sectionOids',
  DOMAIN_IDS = 'domainIds',
  ADDITIONAL_FIELD = 'additionalField'
}
