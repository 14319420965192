import {Component, Injector, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {AdvancedSearchDirective} from './advanced-search.directive';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {AdvancedSearch} from '@twpub/core/models';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-advanced-search-wrapper',
  templateUrl: './advanced-search-wrapper.component.html',
  styleUrls: ['./advanced-search-wrapper.component.scss']
})
export class AdvancedSearchWrapperComponent extends BaseWrapperComponent<AdvancedSearch> implements OnChanges {
  override type = PageCompType.AdvancedSearch;
  @ViewChild(AdvancedSearchDirective, {static: true}) pubAdvancedSearch!: AdvancedSearchDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  override createComponent(): AdvancedSearch {
    const instance = super.createComponent();
    instance.includeAdditionalField.subscribe((fieldName) => {
      this.sharedStateService.includeAdditionalField(fieldName);
    })
    this.update();
    return instance;
  }

  override update() {
    super.update((comp) => {
      comp.additionalField = this.sessionObj.additionalField || '';
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.SEARCH_TERM])) {
      this.update();
    }
  }

  getViewContainerRef() {
    return this.pubAdvancedSearch.viewContainerRef;
  }
}
