import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RefLinkContentParser} from '@twpub/core/services/ref-link-content-parser';

@NgModule({
  declarations: [],
  providers: [RefLinkContentParser],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
