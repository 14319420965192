import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ConceptSelection, DisplayField} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {ConceptService, NavigationService, SharedStateService, VirtualFileService} from '@twpub/core/services';

@Component({
  selector: 'pub-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss']
})
export class CardContentComponent implements OnInit, OnChanges {
  @Input() conceptId?: number;
  @Input() termId?: number;
  @Input() imageFields?: string[] = []
  @Input() displayFields?: DisplayField[] = [];
  fileIds: number[] = [];

  constructor(private logger: NGXLogger, private conceptService: ConceptService,
              private virtualFileService: VirtualFileService,
              private sharedStateService: SharedStateService,
              private naviService: NavigationService) {
  }

  ngOnInit(): void {
    this.findFileIds();
  }

  ngOnChanges(): void {
    this.findFileIds();
  }

  private findFileIds() {
    if (this.conceptId) {
      this.conceptService.getConcept(this.conceptId).subscribe({
        next: (concept) => {
          const term = concept.terms.filter(t => t.id === this.termId).pop();
          this.fileIds = this.virtualFileService.getImages(concept, term, this.imageFields);
        }
      })
    } else {
      this.fileIds = [];
    }
  }

  selectConcept(selection: ConceptSelection) {
    this.sharedStateService.selectConcept(selection);
    this.logger.debug('CardContentComponent.selectConcept:', {selection})
    const url = this.naviService.createUrl(undefined, selection.conceptId, selection.termId);
    this.naviService.go(url);
  }
}
