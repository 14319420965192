import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {BaseResultListComponent} from '@twpub/shared/components/base';
import {FieldProps, ResultList, TermSearchResult} from '@twpub/core/models';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {
  ClientConfigurationScheme,
  ComponentConfiguration,
  ComponentInit,
  fromPageEvent,
  InitialConfig
} from '@twpub/core/utils';
import {ResultListProp} from '@twpub/core/constants';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {Router} from '@angular/router';
import {FieldService} from '@twpub/core/services';

@Component({
  selector: 'pub-card-result-list',
  templateUrl: './card-result-list.component.html',
  styleUrls: ['./card-result-list.component.scss']
})
export class CardResultListComponent extends BaseResultListComponent implements ResultList, OnInit {
  @Input() selectedLang?: string;
  imageFields: string[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [
      {
        key: ResultListProp.InitialSearch,
        label: 'Results visible on page load',
        controlType: ControlType.Boolean,
        description: 'Automatically list all terms when page is opened, before any search'
      },
      {
        key: ResultListProp.Fields,
        label: 'Field names',
        controlType: ControlType.Textbox,
        description: 'Enter names of entry fields (concept and term level) to be included in the result list. Separate names by semi-colon (;)'
      }
    ];

    constructor() {
      super(CardResultListComponent, 'CardResultListComponent', 'Card Result List', [PageCompType.ResultList]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultList>(CardResultListComponent, this.name, initialConfig);
    }
  }

  constructor(private injector: Injector, private router: Router, private fieldService: FieldService) {
    super(injector);
  }

  ngOnInit(): void {
    this.sessionObj.resultPaginator.pageSize = 15;
    this.onInit();
    this.fetchLangNames();
    this.performSearch(this.sessionObj.resultPaginator, true);
    this.imageFields = this.fieldService.getImageFields();
  }

  pageChange(event: PageEvent) {
    this.performSearch(fromPageEvent(event), true);
  }

  cardClick(resData: TermSearchResult, index: number): void {
    this.router.navigate(['/entry', resData.term.conceptId, resData.term.id]);
    this.resultClick(resData, index, true)
  }
}
