import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'pub-external-redirect',
  template: ''
})
export class ExternalRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    const url = this.route.snapshot.paramMap.get('url');
    if (url) {
      window.location.href = url;
    } else {
      this.router.navigate(['/']);
    }
  }
}
