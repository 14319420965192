/* eslint-disable dot-notation */
import {Component, OnInit} from '@angular/core';
import {filter} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {TITLE} from '@twpub/core/constants';

@Component({
  selector: 'pub-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private title: Title, private router: Router) {
  }

  ngOnInit(): void {
    this.setPageTitle();
  }

  /**
   * Sets page title to <code>data.title</code> specified in routes.
   * @see https://dev.to/nightwolfdev/dynamically-set-page-title-in-angular-app-35ca
   */
  setPageTitle(): void {
    const defaultPageTitle = TITLE;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        if (!child) {
          return this.activatedRoute.snapshot.data['title'] || defaultPageTitle;
        }
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'] || defaultPageTitle;
        }
      })
    ).subscribe((title: string) => this.title.setTitle(title));
  }
}
