import {Component, OnInit} from '@angular/core';
import {TermwebConfiguration} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {ClientConfigurationService, TermwebStatusService} from '@twpub/core/services';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'pub-termweb-connection-settings',
  templateUrl: './termweb-connection-settings.component.html',
  styleUrls: ['./termweb-connection-settings.component.scss']
})
export class TermwebConnectionSettingsComponent implements OnInit {
  termwebConfigurations: TermwebConfiguration[] = [];
  termwebMessageType?: string;
  termwebMessage?: string;
  editConfiguration?: TermwebConfiguration;
  currentConfiguration?: TermwebConfiguration;

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService,
              private statusService: TermwebStatusService) {
  }

  ngOnInit(): void {
    this.fetchConfigurations();
    this.getTermWebStatus();
  }

  private fetchConfigurations() {
    this.configService.getTermWebConfigurations().subscribe({
      next: (configs) => {
        this.termwebConfigurations = configs;
        this.currentConfiguration = configs?.[0];
        if (!this.currentConfiguration) {
          this.editConfiguration = this.initNewConfiguration();
        }
      }
    });
  }

  private initNewConfiguration(): TermwebConfiguration {
    return {id: 1, url: '', client: '', userName: '', password: ''} as TermwebConfiguration;
  }

  private getTermWebStatus() {
    this.statusService.getTermWebStatus().subscribe({
      next: status => {
        if (status.ok) {
          this.termwebMessageType = 'success'
          this.termwebMessage = 'TermWeb connection successful';
        } else {
          this.termwebMessageType = 'danger'
          this.termwebMessage = 'TermWeb connection failed: ' + status.message;
        }
      },
      error: (error) => this.handleError(error)
    });
  }

  private handleError(error: HttpErrorResponse) {
    this.termwebMessageType = 'danger'
    this.termwebMessage = error.message;
    this.logger.error('Connection error', error.message);
  }

  saveConfiguration(configuration: TermwebConfiguration | undefined) {
    if (configuration) {
      this.configService.saveTermWebConfiguration(configuration).subscribe({
        next: () => {
          this.configService.getTermWebConfigurations().subscribe({
            next: (configs) => {
              this.termwebConfigurations = configs;
            }
          });
        }
      });
    }
    this.editConfiguration = undefined;
  }

  setEditConfig(config: TermwebConfiguration) {
    this.editConfiguration = config;
  }
}
