import {PageEvent} from '@angular/material/paginator';
import {PaginationData} from '@twpub/core/models';

export function fromPageEvent(event: PageEvent): PaginationData {
  const {pageIndex, previousPageIndex, pageSize, length} = event;
  const itemsStartIndex = pageIndex * pageSize + 1;
  const itemsEndIndex = Math.min(itemsStartIndex + pageSize - 1, length);
  const totalPages = Math.ceil(length / pageSize);
  return {
    totalItems: length,
    pageSize,
    pageIndex,
    previousPageIndex: previousPageIndex || 0,
    itemsStartIndex,
    itemsEndIndex,
    totalPages
  };
}
