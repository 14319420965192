export enum ClientProp {
  Locale = 'locale',
  TitleName = 'titleName',
  PoweredByType = 'poweredByType',
  PowerText = 'powerText'
}

export enum PoweredByType {
  None = 'none',
  Text = 'text',
  Image = 'image'
}

export enum CompProp {
  Label = 'label',
  Visible = 'visible',
}

export enum ResultListProp {
  IncludeSynonyms = 'includeSynonyms',
  IncludeTranslations = 'includeTranslations',
  InitialSearch = 'initialSearch',
  Fields = 'resultFields',
  ImageFields = 'imageFields',
  IncludeTermLink = 'includeTermLink',
  IncludeResultLink = 'includeResultLink',
  ResultLinkLabel = 'resultLinkLabel',
  PageSize = 'pageSize'
}

export type SearchResultConfig = {
  [ResultListProp.IncludeSynonyms]: boolean,
  [ResultListProp.IncludeTranslations]?: boolean,
  [ResultListProp.InitialSearch]: boolean,
  [ResultListProp.Fields]?: string
};
