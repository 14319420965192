import {Component, Input} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {FieldProps, VirtualFile} from '@twpub/core/models';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {AttachmentRender} from '@twpub/core/models/wrappers/attachment-render';
import {
  VirtualFileContentDialogComponent
} from '@twpub/shared/components/renders/virtual-file-content-dialog/virtual-file-content-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {VirtualFileService} from '@twpub/core/services';

@Component({
  selector: 'pub-default-attachment-render',
  templateUrl: './default-attachment-render.component.html',
  styleUrls: ['./default-attachment-render.component.scss']
})
export class DefaultAttachmentRenderComponent extends BaseConfigurableComponent implements AttachmentRender {
  static readonly SHOW_IMAGE_INLINE = 'showImageInline';
  static readonly SHOW_VIDEO_INLINE = 'showVideoInline';
  static readonly SHOW_AUDIO_INLINE = 'showAudioInline';

  @Input() vfile?: VirtualFile
  @Input() contentUrl?: string
  @Input() thumbUrl?: string
  icon: string = '';

  static readonly compName = 'DefaultAttachmentRenderComponent';
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [
      {
        key: DefaultAttachmentRenderComponent.SHOW_IMAGE_INLINE,
        label: 'Show image inline',
        controlType: ControlType.Boolean
      },
      {
        key: DefaultAttachmentRenderComponent.SHOW_VIDEO_INLINE,
        label: 'Show video inline',
        controlType: ControlType.Boolean
      },
      {
        key: DefaultAttachmentRenderComponent.SHOW_AUDIO_INLINE,
        label: 'Show audio inline',
        controlType: ControlType.Boolean
      }
    ];

    constructor() {
      super(DefaultAttachmentRenderComponent, DefaultAttachmentRenderComponent.compName, 'Default Attachment Render', [PageCompType.AttachmentRender]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<AttachmentRender>(DefaultAttachmentRenderComponent, this.name, initialConfig);
    }
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  openContentDialog(event: MouseEvent) {
    event?.stopPropagation();
    const width: string = '520px';
    const height: string = 'auto';
    this.dialog.open(VirtualFileContentDialogComponent,
      {width, height, data: {vfile: this.vfile, contentUrl: this.contentUrl}}
    );
  }

  getIcon() {
    if (this.isPdf()) return 'picture_as_pdf';
    if (this.isImage()) return 'image_file';
    if (this.isAudio()) return 'audio_file';
    if (this.isVideo()) return 'video_file';
    return 'open_in_new';
  }

  showVideoInline = () => this.isVideo() && this.config?.getBoolean(DefaultAttachmentRenderComponent.SHOW_VIDEO_INLINE);
  showAudioInline = () => this.isAudio() && this.config?.getBoolean(DefaultAttachmentRenderComponent.SHOW_AUDIO_INLINE);
  showImageInline = () => this.isImage() && this.config?.getBoolean(DefaultAttachmentRenderComponent.SHOW_IMAGE_INLINE);

  isImage = () => VirtualFileService.isImage(this.vfile);
  isPdf = () => VirtualFileService.isPdf(this.vfile);
  isAudio = () => VirtualFileService.isAudio(this.vfile);
  isVideo = () => VirtualFileService.isVideo(this.vfile);
  isCommonType = () => this.isImage() || this.isPdf() || this.isAudio() || this.isVideo();
  showLink = () => !this.showAudioInline() && !this.showImageInline() && !this.showVideoInline();

  getLinkName() {
    return (this.vfile?.name || '') + (!this.isCommonType() ? '.' + this.vfile?.fileType : '');
  }
}
