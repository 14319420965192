import {Component, Input} from '@angular/core';
import {SessionObject} from '@twpub/core/models';
import {FeatureFlagService} from '@twpub/core/services';

@Component({
  selector: 'pub-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent {
  @Input() sessionObj?: SessionObject;

  constructor(private featureFlagService: FeatureFlagService) {
  }

  isEnabled(): boolean {
    return this.featureFlagService.isFeatureEnabled('DebugPanelComponent');
  }
}
