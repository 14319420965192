<!--suppress AngularUndefinedBinding -->
<ng-container *ngIf="visible">
  <form [formGroup]="searchForm">
    <digi-form-input-search
      [afLabel]="label"
      afButtonType="submit"
      [afButtonText]="buttonLabel"
      [afValue]="searchTerm"
      formControlName="searchText"
      (afOnClick)="searchClick()"
    ></digi-form-input-search>
  </form>
</ng-container>
