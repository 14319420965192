import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {VirtualFileService} from '@twpub/core/services/virtual-file.service';
import {NGXLogger} from 'ngx-logger';

import {ImageData} from '@twpub/core/models/image-data';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'pub-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnInit, OnChanges {
  @Input() entryId?: number = 0;
  @Input() fileIds: number[] = [];

  images?: ImageData[];
  image?: ImageData;
  galleryId: string = '';
  imageReady: boolean = false;

  constructor(private logger: NGXLogger, private fileService: VirtualFileService) {
  }

  ngOnInit(): void {
    this.loadImages();
  }

  ngOnChanges(): void {
    this.loadImages();
  }

  private loadImages() {
    if (this.fileIds?.length) {
      this.logger.debug('CardImageComponent.loadImages:', {fileIds: this.fileIds})
      this.galleryId = 'gallery-' + this.entryId;
      const imageObs = this.fileIds.map(fileId => this.fileService.loadImageData(fileId));
      forkJoin(imageObs).subscribe({
        next: (imageDatas) => {
          this.images = imageDatas;
          this.image = imageDatas[0];

          const img = new Image();
          img.src = this.image.thumbUrl;
          img.onload = () => {
            if(this.image) {
              const scale = 1.3
              const aspectRatio = img.width / img.height;
              this.image.thumbWidth = img.width*scale;
              this.image.thumbHeight = img.height*scale;
              this.imageReady = true;
            }
          };
        },
        error: (error) => {
          this.logger.error('Failed to load images', error);
        }
      });
    }
  }
}
