import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConceptSelection} from '@twpub/core/models';
import {RefLink, RefLinkType} from '@twpub/core/models/ref-link';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'pub-internal-link-render',
  templateUrl: './internal-link-render.component.html',
  styleUrls: ['./internal-link-render.component.scss']
})
export class InternalLinkRenderComponent implements OnInit {
  @Input() refLink!: RefLink;
  @Input() text: string = '';
  @Input() useRouterLink: boolean = false;

  @Output() conceptSelected = new EventEmitter<ConceptSelection>();

  linkText: string = '';
  routerLink: string | any[] = '/';

  constructor(private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.linkText = (this.refLink.type === RefLinkType.TERM_LINK_DYNAMIC_TYPE ? this.refLink.dynamicText : this.text) || '';
    if (this.useRouterLink) {
      this.routerLink = ['/entry', this.refLink.conceptId, this.refLink.termId]
    }
  }

  onClick(conceptId: number, termId?: number) {
    this.logger.trace('InternalLinkRenderComponent.onClick: ', {conceptId, termId, useRouterLink: this.useRouterLink});
    this.conceptSelected.emit({conceptId, termId});
    return true;
  }
}
