<div class="container p-4">
  <div class="header-container">
    <h4>Sections</h4>
  </div>
  <table mat-table [dataSource]="sections" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef>ID</th>
      <td mat-cell class="cell-numeric" *matCellDef="let section"> {{section.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let section"> {{section.name}} </td>
    </ng-container>

    <ng-container matColumnDef="concepts">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef> Concepts</th>
      <td mat-cell class="cell-numeric" *matCellDef="let section"> {{section.numberOfConcepts}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Visibility</th>
      <td mat-cell *matCellDef="let section">
        <pub-icon-button [tooltip]="section.visible ? 'Visible':'Hidden'" (buttonClick)="changeVisibility(section.id)"
                         color="primary" [withText]="false"
                         [icon]="section.visible ? 'visibility' : 'visibility_off'"></pub-icon-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="!row?.visible?'row-disabled':''">

    </tr>
  </table>
</div>
