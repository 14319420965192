import {Component, OnInit} from '@angular/core';
import {ClientConfigurationService, SessionService} from '@twpub/core/services';
import {ClientProp, PoweredByType} from '@twpub/core/constants';

@Component({
  selector: 'pub-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent implements OnInit {
  pbType: string = PoweredByType.None;
  powerText: string = 'Powered by TermWebPublish';
  readonly PoweredByType = PoweredByType;

  constructor(private clientConfigService: ClientConfigurationService, private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.clientConfigService.getDefaultConfiguration(this.sessionService.getSession().configId).subscribe({
      next: config => {
        this.pbType = config.props[ClientProp.PoweredByType] || PoweredByType.None;
        this.powerText = config.props[ClientProp.PowerText] || 'Powered by TermWebPublish';
      }
    });

  }
}
