<digi-layout-block afVerticalPadding="true" *ngIf="visible && concept">
  <digi-typography>
    <div class="result-links">
      <digi-link [afLinkContainer]="true">
        <a routerLink="/">
          <digi-icon-chevron-left slot="icon"></digi-icon-chevron-left>
          Back to results</a>
      </digi-link>
      <digi-link [afLinkContainer]="true"><a routerLink="/" (click)="clearSearch()">New search</a></digi-link>
    </div>
    <digi-table afVariation="secondary">
      <table>
        <tbody>
        <tr>
          <td>{{ getLanguageName(sourceLangCodes?.[0]) }}</td>
          <td [lang]="getLangCode(sourceLangCodes?.[0])">{{ getSelectedTermName() }}</td>
        </tr>
        <tr *ngIf="includeSynonyms">
          <td>Synonym</td>
          <td [lang]="getLangCode(sourceLangCodes?.[0])">{{ getSynonymTermNames(sourceLangCodes?.[0]) }}</td>
        </tr>
        <tr pub-field-render [useRouterLink]="true" *ngFor="let field of conceptFields" [field]="field"
            (conceptSelected)="selectConcept($event)"></tr>
        <tr>
          <td>{{ getTargetLanguageName() }}</td>
          <td [lang]="getLangCode(targetLangCodes?.[0])">{{ getTargetTermNames() }}</td>
        </tr>
        </tbody>
      </table>
    </digi-table>
  </digi-typography>
</digi-layout-block>
