import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {
  currentTime?: number;

  transform(dateStr?: string | null) {
    if (!dateStr) {
      return '';
    }
    const currentTime = this.currentTime ? this.currentTime : Date.now();
    const otherTime = new Date(dateStr).getTime();
    const diffDays = Math.floor(((currentTime - otherTime) / 1000) / 86400);
    if (Math.abs(diffDays) < 30) {
      return this.convert(currentTime, otherTime);
    } else {
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(dateStr, 'yyyy-MM-dd');
    }
  }

  private convert(currentTime: number, otherTime: number) {
    const isPast = currentTime - otherTime > 0;
    const diffSecs = Math.abs(currentTime - otherTime) / 1000;
    const diffDays = Math.floor(diffSecs / 86400);
    const diffMins = Math.floor((diffSecs + 30) / 60);
    const diffHours = Math.floor((diffSecs + 1800) / 3600);
    if (diffDays == 0) {
      return diffSecs < 30 && 'Just now' ||
        diffSecs < 90 && (isPast ? 'One minute ago' : 'In one minute') ||
        diffSecs < 3570 && (isPast ? diffMins + ' minutes ago' : 'In ' + diffMins + ' minutes') ||
        diffSecs < 5400 && (isPast ? 'One hour ago' : 'In one hour') ||
        (isPast ? diffHours + ' hours ago' : 'In ' + diffHours + ' hours');
    }
    return isPast ? diffDays + ' days ago' : 'In ' + diffDays + ' days';
  }
}
