import {Component, Injector, ViewChild} from '@angular/core';
import {ResultPage, SearchPage} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {ResultPageDirective} from './result-page.directive';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-result-page-wrapper',
  templateUrl: './result-page-wrapper.component.html',
  styleUrls: ['./result-page-wrapper.component.scss']
})
export class ResultPageWrapperComponent extends BaseWrapperComponent<ResultPage> {
  override type = PageCompType.ResultPage;

  @ViewChild(ResultPageDirective, {static: true}) pubResultPage!: ResultPageDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  override createComponent(): SearchPage {
    const comp = super.createComponent();
    comp.config = this.componentConfig;
    comp.pageCompType = this.type;
    return comp;
  }

  getViewContainerRef() {
    return this.pubResultPage.viewContainerRef;
  }
}
