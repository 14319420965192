import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private location: Location, private router: Router) {
  }

  public createUrl(dictId?: number, conceptId?: number, termId?: number) {
    return (dictId ? `dict/${dictId}/` : '') + (conceptId ? `entry/${conceptId}` : '') + (termId ? `/${termId}` : '');
  }

  public replaceState(url: string) {
    this.location.replaceState(url)
  }

  public async go(url: string) {
    await this.router.navigateByUrl(url);
  }
}
