<p>Configure connection to TermWeb</p>
<form [formGroup]="configForm">
    <input formControlName="id" type="hidden">
    <div class="row">
        <div class="col-sm">
            <mat-form-field id="url-field">
                <mat-label>URL</mat-label>
                <input matInput formControlName="url">
                <mat-error *ngIf="configForm.get('url')?.hasError('required')">URL is required</mat-error>
                <mat-error *ngIf="configForm.get('url')?.hasError('pattern')">Enter a valid URL</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <mat-form-field>
                <mat-label>Client</mat-label>
                <input matInput formControlName="client">
                <mat-error *ngIf="configForm.get('client')?.hasError('required')">Client is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <mat-form-field>
                <mat-label>User name</mat-label>
                <input matInput formControlName="userName">
                <mat-error *ngIf="configForm.get('userName')?.hasError('required')">User name is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <mat-form-field>
                <mat-label data-testid="password-label">{{ passwordLabel }}</mat-label>
                <input matInput type="text" formControlName="password">
                <mat-error *ngIf="configForm.get('password')?.hasError('required')">Password is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="button-container">
        <button mat-raised-button color="primary" (click)="save()" [disabled]="configForm.invalid">Save</button>
        <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
</form>
