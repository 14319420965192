<ng-container *ngIf="visible">
  <footer>
    <div class="flexrow">
      <digi-layout-container afNoGutter="true">
        <digi-typography>
          <div class="row">
        <!--    <div class="col">
              <digi-logo afColor="secondary"></digi-logo>
            </div>-->
        <!--    <div class="col">
              <digi-link *ngIf="showA11yStatementLink" [afLinkContainer]="true">
                <a href="/tillganglighetsredogorelse">
                  <digi-icon-accessibility-universal slot="icon"></digi-icon-accessibility-universal>
                  Accessibility statement
                </a>
              </digi-link>
            </div>-->
            <div class="col">
              <p>{{contactTitle}}</p>
              <p>
                <digi-link [afLinkContainer]="true">
                  <a href="mailto:{{contactEmail}}">
                    <digi-icon-external-link-alt slot="icon"></digi-icon-external-link-alt>
                    {{contactEmailText}}</a>
                </digi-link>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col link mb-1">
              <pub-powered-by></pub-powered-by>
            </div>
          </div>
        </digi-typography>
      </digi-layout-container>
    </div>
  </footer>
</ng-container>
