import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ConceptSelection, DisplayEntry, DisplayField, DisplayTerm} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'pub-term-render',
  templateUrl: './term-render.component.html'
})
export class TermRenderComponent implements OnChanges {
  @Input() term?: DisplayTerm
  @Input() focus: boolean = false;
  @Input() type: string = '';

  @Output() conceptSelected = new EventEmitter<ConceptSelection>()
  termNameField!: DisplayField;

  constructor(private logger: NGXLogger) {
  }

  ngOnChanges() {
    this.termNameField = {oid: 'term', label: 'Term', value: this.term?.name || ''};
  }

  selectConcept(selection: ConceptSelection) {
    this.logger.trace('TermRenderComponent.selectConcept:', {selection})
    this.conceptSelected?.emit(selection);
  }

  getTermFields(): DisplayField[] | undefined {
    return this.getFieldEntries(this.term);
  }

  private getFieldEntries(entry: DisplayEntry | undefined) {
    return entry?.fields || [];
  }
}
