import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {SectionSelector} from '@twpub/core/models/wrappers/section-selector';
import {FieldProps, Section} from '@twpub/core/models';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'pub-section-dropdown',
  templateUrl: './section-dropdown.component.html',
  styleUrls: ['./section-dropdown.component.scss']
})
export class SectionDropdownComponent extends BaseConfigurableComponent implements SectionSelector {
  static readonly compName = 'SectionDropdownComponent';
  static MULTIPLE_SELECT = 'multipleSelect';

  @Input() currentSections?: string[] = []
  @Input() sections?: Section[]
  multipleSelect: boolean = false;
  allSelected: boolean = false;
  singleSelected: boolean = false;
  readonly allSelectedLabel: string = 'All Sections';

  @ViewChild('select') select!: MatSelect;

  /**
   * Outputs the selected section oids.
   */
  @Output() selectSections = new EventEmitter<string[]>()
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {
        key: SectionDropdownComponent.MULTIPLE_SELECT,
        label: 'Multiple selections',
        controlType: ControlType.Boolean,
        description: 'Allow multiple selections in dropdown'
      }
    ];

    constructor() {
      super(SectionDropdownComponent, SectionDropdownComponent.compName, 'Dropdown List', [PageCompType.SectionSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<SectionSelector>(SectionDropdownComponent, this.name, initialConfig);
    }
  }

  override doUpdate(): void {
    super.doUpdate();
    this.multipleSelect = this.config?.getBoolean(SectionDropdownComponent.MULTIPLE_SELECT, false);
    this.allSelected = !this.currentSections?.length;
    this.singleSelected = this.currentSections?.length === 1;
  }

  onSelect(selValue: string | string[]) {
    this.logger.trace('SectionDropdownComponent.onSelect:', {selValue})
    if (!this.sections?.length) return;
    let selectedOids: string[];
    if (typeof selValue === 'string') {
      selectedOids = selValue === '' ? this.sections.map(s => s.oid) : [selValue];
    } else {
      selectedOids = selValue || [];
    }
    const isAllSelected = selectedOids.length === this.sections.length;
    const isNoneSelected = selectedOids.length === 0;

    if (isAllSelected) {
      selectedOids = [];
    } else if (isNoneSelected) {
      selectedOids = [this.sections[0].oid];
    } else {
      selectedOids = selectedOids.filter(oid => this.sections?.some(section => section.oid === oid));
    }
    this.allSelected = isAllSelected;
    this.singleSelected = selectedOids.length === 1;
    this.currentSections = selectedOids;
    this.selectSections.emit(selectedOids)
  }

  toggleAllSelection(all: boolean = true) {
    this.select.options.forEach((item: MatOption) => {
      if (all) {
        item.value !== '' && item.select();
      } else {
        item.deselect()
      }
    });
  }

  getSelectedValue() {
    let value: string[] | string;
    if (this.multipleSelect) {
      if (this.currentSections?.length) {
        value = this.currentSections;
      } else {
        value = this.sections?.map(s => s.oid) || [];
      }
    } else {
      value = this.currentSections?.[0] || '';
    }
    return value;
  }

  isDisabled(section: Section) {
    return this.multipleSelect && this.currentSections?.length === 1 && this.currentSections?.[0] === section.oid;
  }
}
