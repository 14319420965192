import {Component, Input, OnInit} from '@angular/core';
import {DictionaryStatistics} from '@twpub/core/models/dictionary-statistics';
import {Language} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {DictionaryService} from '@twpub/core/services';

@Component({
  selector: 'pub-languages-panel',
  templateUrl: './languages-panel.component.html',
  styleUrls: ['./languages-panel.component.scss']
})
export class LanguagesPanelComponent implements OnInit {
  displayedColumns: string[] = ['code', 'name', 'terms', 'actions'];
  @Input() dictStat!: DictionaryStatistics;
  languages: Language[] = [];

  constructor(private logger: NGXLogger, private dictService: DictionaryService) {
  }

  ngOnInit(): void {
    this.dictService.getAllLanguages(this.dictStat.id).subscribe((languages) => {
      this.languages = languages;
    });
  }

  changeVisibility(id: number) {
    const oldLang = this.languages.find(lang => lang.id === id);
    this.logger.debug('LanguagesPanelComponent.changeVisibility: ', {oldLang, langs: this.languages})
    if (oldLang) {
      const language: Language = {
        ...oldLang,
        visible: !oldLang.visible
      };
      this.dictService.updateLanguage(language).subscribe(() => {
        oldLang.visible = language.visible;
      });
    }
  }
}
