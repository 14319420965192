import {Component, Injector, OnChanges, ViewChild} from '@angular/core';
import {HeaderDirective} from './header.directive';
import {Header} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrls: ['./header-wrapper.component.scss']
})
export class HeaderWrapperComponent extends BaseWrapperComponent<Header> implements OnChanges {
  override type = PageCompType.Header;

  @ViewChild(HeaderDirective, {static: true}) pubHeader!: HeaderDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  ngOnChanges(): void {
    this.update();
  }

  getViewContainerRef() {
    return this.pubHeader.viewContainerRef;
  }
}
