import {Component, OnInit} from '@angular/core';
import {DictionaryService} from '@twpub/core/services';
import {DictionaryStatistics} from '@twpub/core/models/dictionary-statistics';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {timeStr} from '@twpub/core/utils';
import {DictionaryDetailsComponent} from '../dictionary-details/dictionary-details.component';
import {NotifierService} from '../../services/notifier.service';

@Component({
  selector: 'pub-dictionaries-panel',
  templateUrl: './dictionaries-panel.component.html',
  styleUrls: ['./dictionaries-panel.component.scss']
})
export class DictionariesPanelComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'twConceptCount', 'pubConceptCount', 'syncStatus', 'syncDate', 'syncTime', 'actions'];
  dictStats: DictionaryStatistics[] = [];
  isRefreshing = false;

  protected readonly timeStr = timeStr;

  constructor(private dictService: DictionaryService, private dialog: MatDialog, private notifier: NotifierService) {
  }

  ngOnInit(): void {
    this.fetchStats();
  }

  private fetchStats() {
    this.dictService.getDictionaryStatistics().subscribe({
      next: (stats) => {
        this.dictStats = this.getSorted(stats);
        this.isRefreshing = false;
      }
    })
  }

  private getSorted(stats: DictionaryStatistics[]) {
    return stats.sort((a, b) => {
      if (a.syncStats.status === 'IDLE' && b.syncStats.status !== 'IDLE') {
        return 1;
      } else if (a.syncStats.status !== 'IDLE' && b.syncStats.status === 'IDLE') {
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  refreshData() {
    this.isRefreshing = true;
    this.dictService.refreshDictionaries().subscribe({
      next: () => {
        this.fetchStats();
      }
    })
  }

  getConceptCount(stat: DictionaryStatistics) {
    if (this.isComplete(stat)) {
      return stat.publishedConceptCount;
    } else {
      return '-';
    }
  }

  fetchUpdates(stat: DictionaryStatistics) {
    if (this.isNotSynced(stat)) {
      this.reindexDictionaryPrompt(stat);
    } else {
      this.checkForUpdates(stat);
    }
  }

  reindexDictionaryPrompt(stat: DictionaryStatistics) {
    const syncStats = stat?.syncStats;
    let message: string[];
    if (syncStats?.status === 'IDLE') {
      message = ['Start indexing "' + stat?.name + '"?'];
    } else {
      message = ['Are you sure you want to reindex "' + stat?.name + '"?',
        syncStats?.initialSyncTimeMs ? 'Estimated time: ' + timeStr(syncStats.initialSyncTimeMs) : ''];
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {message}});

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        if (stat) {
          stat.syncStats.status = 'IN_PROGRESS';
        }
        this.dictService.reindexDictionary(stat.id).subscribe({
          next: () => {
            this.fetchStats();
          }
        })
      }
    });
  }

  private checkForUpdates(stat: DictionaryStatistics) {
    if (stat) {
      stat.syncStats.status = 'IN_PROGRESS';
    }
    this.dictService.refreshDictionary(stat.id).subscribe({
      next: (syncStats) => {
        let message: string;
        const dictName = stat?.name;
        if (syncStats.lastSyncConceptCount === 0) {
          message = `No changes in "${dictName}"`;
        } else {
          message = `Updated ${syncStats.lastSyncConceptCount} concepts in "${dictName}"`;
        }
        this.notifier.showPopupMessage(message);
        this.fetchStats();
      }
    })
  }

  isComplete(stat: DictionaryStatistics) {
    return stat.syncStats?.status === 'COMPLETE' || stat.syncStats?.status === 'WARNING';
  }

  isInProgess(stat: DictionaryStatistics) {
    return stat.syncStats.status === 'IN_PROGRESS';
  }

  isNotSynced(stat: DictionaryStatistics) {
    return stat.syncStats.status === 'IDLE';
  }

  openDictionaryDetails(dictStat: DictionaryStatistics): void {
    const dialogRef = this.dialog.open(DictionaryDetailsComponent, {
      width: '80%',
      height: '80%',
      data: {dictStat}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchStats();
    });
  }

  getNameTooltip(dictStat: DictionaryStatistics): string {
    if (!this.isComplete(dictStat)) {
      return 'Dictionary is not synchronized';
    } else if (!dictStat.visible) {
      return 'No visible sections and/or languages';
    } else {
      return '';
    }
  }
}
