import {Component} from '@angular/core';

@Component({
  selector: 'pub-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss']
})
export class SettingsPanelComponent {

}
