<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Scheduled synchronization settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>Automatically check for updates in synchronized dictionaries.</p>
    <mat-form-field>
      <mat-label>Check for updates</mat-label>
      <mat-select [(value)]="selectedInterval" (selectionChange)="onSelectionChange()">
        <mat-option *ngFor="let interval of updateIntervals" [value]="interval.value">
          {{ interval.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="selectedInterval > 0 && scheduledStatus?.lastRunTime?.length" class="time">Last
      synchronization: {{ scheduledStatus?.lastRunTime | prettyDate }}
    </div>
    <div *ngIf="scheduledStatus?.nextRunTime?.length" class="time">Next
      synchronization: {{ scheduledStatus?.nextRunTime | prettyDate }}
    </div>
  </mat-card-content>
</mat-card>
