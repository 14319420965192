<div class="container p-4">
  <pub-configuration-list *ngIf="!editConfiguration" (setEditConfig)="editConfiguration=$event">
  </pub-configuration-list>
  <div class="row" *ngIf="editConfiguration">
    <div class="col-sm">
      <pub-edit-configuration-form [editConfiguration]="editConfiguration"
                                   (editingDone)="editingDone()"></pub-edit-configuration-form>
    </div>
  </div>
</div>
