import {Component, Injector, ViewChild} from '@angular/core'
import {SourceLanguageSelectorDirective} from './source-language-selector.directive'
import {DictionaryService} from '@twpub/core/services';
import {PageCompType} from '@twpub/core/enums';
import {BaseLanguageWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-source-language-wrapper',
  templateUrl: './source-language-wrapper.component.html',
  styleUrls: ['./source-language-wrapper.component.scss']
})
/**
 * Wrapper component for selection of language.
 */
export class SourceLanguageWrapperComponent extends BaseLanguageWrapperComponent {
  override type = PageCompType.SourceLanguageSelector;
  @ViewChild(SourceLanguageSelectorDirective, {static: true}) pubSrcLangSelect!: SourceLanguageSelectorDirective

  constructor(injector: Injector, dictService: DictionaryService) {
    super(injector, dictService);
  }

  getViewContainerRef() {
    return this.pubSrcLangSelect.viewContainerRef;
  }

  protected selectLanguages(langCodes: string[]): void {
    this.sharedStateService.clearConcept();
    return this.sharedStateService.selectSourceLanguages(langCodes);
  }
}
