<ng-container *ngIf="visible">
  <header [ngClass]="cssClassName">
    <div class="logo"><img src="assets/img/twpub_logo2.png" alt="image"></div>
      <pub-configuration-selector></pub-configuration-selector>
     <div class="header-nav-container">
      <div class="header-nav">
        <a [routerLink]="['/']">Search</a>
        <a [routerLink]="['/admin/home']">Admin</a>
      </div>
    </div>
  </header>
</ng-container>
