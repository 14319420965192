import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, Language, LanguageSelector} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-language-dropdown',
  templateUrl: './af-language-dropdown.component.html',
  styleUrls: ['./af-language-dropdown.component.scss']
})
export class AfLanguageDropdownComponent extends BaseConfigurableComponent implements OnInit, LanguageSelector {
  @Input() currentLanguages?: string[];
  @Input() langs?: Language[]

  @Output() selectLanguages: EventEmitter<string[]> = new EventEmitter<string[]>()

  selectLangForm: UntypedFormGroup;

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {key: 'allLanguages', label: 'Include \'all languages\'', controlType: ControlType.Boolean},
      {key: 'defaultValue', label: 'Default language code', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(AfLanguageDropdownComponent, 'AfLanguageDropdownComponent', 'AF Dropdown List', [PageCompType.SourceLanguageSelector, PageCompType.TargetLanguageSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<LanguageSelector>(AfLanguageDropdownComponent, this.name, initialConfig);
    }
  }

  constructor(private fb: UntypedFormBuilder) {
    super();
    this.selectLangForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.doUpdate();
    this.selectLangForm = this.fb.group({selectLang: [null, []]});
  }

  onSelectLanguage() {
    const langCode = this.selectLangForm.value.selectLang;
    this.selectLanguages.emit([langCode])
  }

  getLanguageName(lang: Language) {
    return lang.name;
  }
}
