import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, SearchField} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-search-field',
  templateUrl: './af-search-field.component.html',
  styleUrls: ['./af-search-field.component.scss']
})
export class AfSearchFieldComponent extends BaseConfigurableComponent implements SearchField, OnInit {
  @Input() autoComplete: boolean = true
  @Input() searchTerm?: string
  @Output() searchFor = new EventEmitter<string>()

  buttonLabel?: string = ''

  searchForm: UntypedFormGroup;

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [
      this.LABEL_FIELD,
      this.VISIBLE_FIELD,
      {key: 'buttonLabel', label: 'Button label', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(AfSearchFieldComponent, 'AfSearchFieldComponent', 'AF Search Field', [PageCompType.SearchField]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<SearchField>(AfSearchFieldComponent, this.name, initialConfig);
    }
  }

  constructor(private fb: UntypedFormBuilder) {
    super();
    this.searchForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({searchText: [null, []]});
  }

  override doUpdate() {
    super.doUpdate();
    this.buttonLabel = this.config?.getString('buttonLabel');
  }

  searchClick() {
    const searchText = this.searchForm.value.searchText;
    this.searchFor.emit(searchText)
  }
}
