import {fromPageEvent} from '@twpub/core/utils/from-page-event'; // Must use full path

export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PGDATA = fromPageEvent({
  pageIndex: DEFAULT_PAGE_INDEX,
  previousPageIndex: DEFAULT_PAGE_INDEX,
  pageSize: DEFAULT_PAGE_SIZE,
  length: -1
});
