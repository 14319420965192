<div *ngIf="concept" [ngClass]="cssClassName">
  <div *ngIf="classificationFields?.length" class="fset ccl">
    <div pub-field-render *ngFor="let field of classificationFields" [field]="field"
         (conceptSelected)="selectConcept($event)"></div>
  </div>
  <div *ngIf="conceptFields?.length" class="fset cl">
    <div pub-field-render *ngFor="let field of conceptFields" [field]="field"
         (conceptSelected)="selectConcept($event)"></div>
  </div>
  <ng-container *ngFor="let langCode of sourceLangCodes">
    <mat-card appearance="outlined" *ngIf="hasTermInLanguage(langCode)" class="ll source">
      <mat-card-subtitle><span pub-field-render [field]="getLanguageField(langCode)"></span></mat-card-subtitle>
      <pub-term-render *ngIf="hasSelectedTerm()" [term]="getTerm()" [focus]="true"
                       (conceptSelected)="selectConcept($event)"></pub-term-render>
      <ng-container *ngFor="let term of getSynonymTerms(langCode)">
        <pub-term-render [term]="term" (conceptSelected)="selectConcept($event)"></pub-term-render>
      </ng-container>
    </mat-card>
  </ng-container>
  <ng-container *ngFor="let langCode of getTargetLanguages()">
    <mat-card appearance="outlined" *ngIf="hasTermInLanguage(langCode)" class="ll target">
      <mat-card-subtitle><span pub-field-render [field]="getLanguageField(langCode)"></span></mat-card-subtitle>
      <ng-container *ngFor="let term of getTerms(langCode)">
        <pub-term-render [term]="term" (conceptSelected)="selectConcept($event)"></pub-term-render>
      </ng-container>
    </mat-card>
  </ng-container>
</div>
