<div class="container">
  <div class="row">
    <div class="col-sm">
      <h4>Administration</h4>
    </div>
  </div>
  <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab.value"
                 (selectedIndexChange)="selectedTab.setValue($event)">
    <mat-tab label="Configurations">
      <pub-configurations-panel></pub-configurations-panel>
    </mat-tab>
    <mat-tab label="Settings">
      <pub-settings-panel></pub-settings-panel>
    </mat-tab>
    <mat-tab label="Dictionaries" *ngIf="isConfigurationOk">
      <pub-dictionaries-panel></pub-dictionaries-panel>
    </mat-tab>
  </mat-tab-group>

</div>
