import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExternalRedirectComponent} from '@twpub/shared/components';
import {ResultPageWrapperComponent, SearchPageWrapperComponent} from '@twpub/shared/components/wrappers';
import {ClientConfigurationResolver} from '@twpub/core/utils/ClientConfigurationResolver';
import {SetConfigurationComponent} from '@twpub/shared/components/set-configuration/set-configuration.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      // todo imported routes are not working with SSR
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {path: 'dict/:dictId/entry/:cid/:tid', component: SearchPageWrapperComponent, resolve: {clientConfig: ClientConfigurationResolver}},
  {path: 'dict/:dictId', component: SearchPageWrapperComponent, resolve: {clientConfig: ClientConfigurationResolver}},
  {path: 'entry/:cid', component: ResultPageWrapperComponent, resolve: {clientConfig: ClientConfigurationResolver}},
  {path: 'entry/:cid/:tid', component: ResultPageWrapperComponent, resolve: {clientConfig: ClientConfigurationResolver}},
  {path: 'external/:url', component: ExternalRedirectComponent},
  {path: 'layout/:id', component: SetConfigurationComponent},
  // {path: 'accessibility-statement', component: A11yPageWrapperComponent},
  {path: '', component: SearchPageWrapperComponent, resolve: {clientConfig: ClientConfigurationResolver}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enabledNonBlocking required to stop Keycloak loop on log in.
    // see https://github.com/mauriciovigolo/keycloak-angular/issues/289
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
