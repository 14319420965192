<!--suppress AngularUndefinedBinding -->
<digi-navigation-pagination
  [afTotalPages]="totalPages"
  [afInitActivePage]="activePage"
  [afTotalResults]="totalResults"
  [afCurrentResultStart]="resultStart"
  [afCurrentResultEnd]="resultEnd"
  (afOnPageChange)="pageChange($event)"
  [afResultName]="resultName"
>
</digi-navigation-pagination>
