/**
 * Base class for defining field types for editing the model for a component in the search ui.
 */
import {ComponentFieldModel, ConfigurableComponent} from '../models';
import {Type} from '@angular/core';

export class ComponentModelBase<C extends ConfigurableComponent> {
  cType?: Type<C>;
  componentFields: ComponentFieldModel<any>[] = [];

  constructor(fields: ComponentFieldModel<any>[]) {
    this.componentFields = fields;
  }
}
