import {Injectable, Injector} from '@angular/core';
import {BaseService} from '@twpub/core/services/base.service';
import {catchError, Observable, shareReplay} from 'rxjs';
import {ConceptRelation} from '@twpub/core/models';

@Injectable({
  providedIn: 'root'
})
export class ConceptRelationService extends BaseService {
  constructor(private injector: Injector) {
    super(injector);
  }

  public getRelationsForConcept(conceptId: number): Observable<ConceptRelation[]> {
    return this.http.get<ConceptRelation[]>(this.url('/relations/concept/' + conceptId))
      .pipe(shareReplay(1))
  }
}
