import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {Observable, take} from 'rxjs';
import {ClientConfigurationService, SessionService} from '@twpub/core/services';
import {ClientConfiguration} from '@twpub/core/utils';

export const ClientConfigurationResolver: ResolveFn<ClientConfiguration> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  sessionService: SessionService = inject(SessionService),
  clientConfigService: ClientConfigurationService = inject(ClientConfigurationService)): Observable<ClientConfiguration> => {
  return clientConfigService.getDefaultConfiguration(sessionService.getSession().configId).pipe(take(1));
};

