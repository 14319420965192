import {Component, Input} from '@angular/core';
import {Term} from '@twpub/core/models';

@Component({
  selector: 'pub-source-term-name',
  templateUrl: './source-term-name-render.component.html',
  styleUrls: ['./source-term-name-render.component.scss']
})
export class SourceTermNameRenderComponent {
  @Input() term?: Term;
  @Input() showLang: boolean = false;
}
