import {ControlType} from '../enums';

/**
 * Base class for defining field types for editing the model for a component in the search ui.
 */
export class ComponentFieldModel<T> {
  value?: T;
  key: string;
  label: string;
  description: string;
  required: boolean;
  order: number;
  controlType: ControlType;
  options: { value: string, label: string }[];

  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    description?: string;
    required?: boolean;
    order?: number;
    controlType?: ControlType;
    options?: { value: string, label: string }[];
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.description = options.description || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || ControlType.None;
    this.options = options.options || [];
  }
}
