import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BytesFormatter} from '@twpub/shared/utils/bytes-formatter';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VirtualFile} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {VirtualFileService} from '@twpub/core/services/virtual-file.service';

@Component({
  selector: 'pub-virtual-file-content-dialog',
  templateUrl: './virtual-file-content-dialog.component.html',
  styleUrls: ['./virtual-file-content-dialog.component.scss']
})
export class VirtualFileContentDialogComponent implements OnInit {
  contentUrl?: SafeResourceUrl;
  downloadUrl?: SafeResourceUrl;
  vfile?: VirtualFile;
  openLink: boolean = false;
  downloadLinkName?: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { vfile: VirtualFile, contentUrl: string },
              private dialogRef: MatDialogRef<VirtualFileContentDialogComponent>,
              private logger: NGXLogger,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const {vfile, contentUrl} = this.data;
    this.vfile = vfile;
    this.logger.debug('VirtualFileContentDialogComponent.ngOnInit:', {vfile})
    this.contentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(contentUrl);
    this.downloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(contentUrl + '?download=true');
    !this.isAudio() && this.setOpenInNewWindowLink();
  }

  private setOpenInNewWindowLink() {
    const {name, size, fileType} = this.vfile!;
    this.openLink = this.isPdf() || this.isVideo();
    this.downloadLinkName = `Download ${name}.${fileType} (${BytesFormatter.formatBytes(size)})`;
  }

  isAudio = () => VirtualFileService.isAudio(this.vfile);
  isVideo = () => VirtualFileService.isVideo(this.vfile);
  isPdf = () => VirtualFileService.isPdf(this.vfile);
  isImage = () => VirtualFileService.isImage(this.vfile);

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
