<!--<div class="container-fluid">-->
<div id="page-container">
  <header class="main-header">
    <div class="logo"><img src="assets/img/twpub_logo2.png" alt="TermWebPublish"></div>
    <div class="header-nav-container" *ngIf="showMenu">
      <div class="header-nav">
        <a [routerLink]="['/']">Back to Search</a>
        <a href="javascript:void(0)" style="cursor:pointer" (click)="logoutUser()">Sign out</a>
      </div>
    </div>
  </header>
  <div id="page-content">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div>{{title}}</div>{{versionStr}}
  </footer>
</div>
