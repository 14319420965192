import {Injectable} from '@angular/core';
import {Client, OpenFeature} from '@openfeature/web-sdk';
import {FeatureProvider} from './feature-provider';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private client: Client;

  constructor(private featureProvider: FeatureProvider) {
    OpenFeature.setProvider(featureProvider);
    this.client = OpenFeature.getClient();
  }

  isFeatureEnabled(featureName: string, defaultValue: boolean = false): boolean {
    return this.client.getBooleanValue(featureName, defaultValue);
  }

/*
  getStringFeature(featureName: string, defaultValue: string): string {
    return this.client.getStringValue(featureName, defaultValue);
  }
*/
/*
  async getNumberFeature(featureName: string, defaultValue: number): Promise<number> {
    return await this.client.getNumberValue(featureName, defaultValue);
  }
  */

  /*getObjectFeature(featureName: string, defaultValue: any): any {
    return this.client.getObjectValue(featureName, defaultValue);
  }*/
}
