import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ClientConfigurationService} from '@twpub/core/services';
import {NGXLogger} from 'ngx-logger';

const TERMWEB_API_TAB_INDEX = 1;

@Component({
  selector: 'pub-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  selectedTab = new FormControl(0);
  isConfigurationOk: boolean = false;

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService) {
  }

  ngOnInit(): void {
    this.logger.debug('AdminHomeComponent.ngOnInit');
    this.checkTermWebConfiguration();
  }

  private checkTermWebConfiguration() {
    this.configService.hasTermWebConfiguration().subscribe({
      next: () => {
        this.isConfigurationOk = true;
      },
      error: () => {
        this.isConfigurationOk = false;
        this.selectedTab.setValue(TERMWEB_API_TAB_INDEX);
      }
    });
  }
}
