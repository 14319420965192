<ng-container *ngIf="visible">
  <mat-form-field *ngIf="dictionaries && dictionaries.length>1" [ngClass]="cssClassName" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-select [(value)]="currentDictionary" (selectionChange)="onSelect($event.value)"
                panelClass="dictionary-dropdown">
      <mat-option *ngFor="let dict of dictionaries" [value]="dict.id">{{ dict.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="dictionaries?.length===1" class="d-inline-block">{{ selectedDictionaryName }}</div>
  <div *ngIf="!dictionaries?.length" class="d-inline-block">No dictionary</div>
</ng-container>
