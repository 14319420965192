<ng-container *ngIf="fieldModels.length">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ getCompName() }}</mat-panel-title>
    </mat-expansion-panel-header>
    <!--  <pre>{{compConfig.dump()|json}}</pre>-->
    <div *ngFor="let fieldModel of fieldModels" class="form-row">
      <pub-component-editor-field [fieldModel]="fieldModel" [form]="formGroup"></pub-component-editor-field>
    </div>
  </mat-expansion-panel>
</ng-container>
