import {Injectable, Injector} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {BaseService} from './base.service';
import {DisplayConcept, DisplayTerm} from '@twpub/core/models';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConceptService extends BaseService {
  constructor(private injector: Injector) {
    super(injector);
  }

  getConcept(conceptId: number): Observable<DisplayConcept> {
    return this.http.get<DisplayConcept>(this.url(`/concepts/${conceptId}`));
  }

  getTermsInLanguages(conceptId: number, srcLangs: string[]): Observable<DisplayTerm[]> {
    return this.getConcept(conceptId).pipe(
      map((concept) => concept.terms.filter(term => srcLangs.includes(term.languageCode)))
    );
  }
}
