import {Domain} from '@twpub/core/models/domain';

export class DomainTree {
  id?: number;
  topLevelDomains: Domain[] = [];

  constructor(data: Partial<DomainTree> = {}) {
    this.id = data.id;
    this.topLevelDomains = data.topLevelDomains?.map(d => this.transformDomain(d)) || [];
  }

  private transformDomain(data: any): Domain {
    return {
      ...data,
      children: data.children.map((child: any) => this.transformDomain(child))
    };
  }

  findDomainById(id?: number): Domain | undefined {
    return this.findDomainByIdRecursively(this.topLevelDomains, id);
  }

  private findDomainByIdRecursively(domains: Domain[], id?: number): Domain | undefined {
    for (const domain of domains) {
      if (domain.id === id) {
        return domain;
      }
      const found = this.findDomainByIdRecursively(domain.children, id);
      if (found) {
        return found;
      }
    }
    return undefined;
  }
}
