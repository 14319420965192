import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminPageComponent} from './pages/admin-page/admin-page.component';
import {TITLE} from '@twpub/core/constants';
import {AuthGuard} from '@twpub/shared/guards/auth.guard';
import {AdminHomeComponent} from './components/admin-home/admin-home.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AdminPageComponent,
    title: `${TITLE} - Administration`,
    children: [
      {path: 'home', component: AdminHomeComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
