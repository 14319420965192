import {Component, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DictionaryService} from '@twpub/core/services';
import {Language, LanguageSelector} from '@twpub/core/models';
import {hasSessionChanges} from '@twpub/core/utils';
import {SessionParam} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  template: ''
})
export abstract class BaseLanguageWrapperComponent extends BaseWrapperComponent<LanguageSelector> implements OnChanges {
  @Input() currentLanguages?: string[];
  languages?: Language[]
  protected constructor(protected injector: Injector, protected dictService: DictionaryService) {
    super(injector);
  }

  protected override init() {
    this.fetchLanguages();
  }

  protected getChangesTriggers(): SessionParam[] {
    return [SessionParam.DICT_ID];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, this.getChangesTriggers())) {
      this.logger.debug('BaseLanguageWrapperComponent.ngOnChanges:', {changes})
      this.fetchLanguages();
    }
  }

  private fetchLanguages() {
    const dictId = this.sessionObj?.dictId;
    if (dictId) {
      // const locale = this.sessionObj?.locale;
      this.logger.debug('BaseLanguageWrapperComponent.fetchLanguages:', {dictId})
      this.dictService.getLanguages(dictId).subscribe({
        next: (languages) => {
          this.languages = languages;
          this.update();
        }
      })
    }
  }

  override createComponent(): LanguageSelector {
    const comp = super.createComponent();
    comp.selectLanguages.subscribe((langCodes: string[]) => this.selectLanguages(langCodes));
    return comp;
  }

  protected abstract selectLanguages(langCodes: string[]): void;

  override update(updateFn?: (comp: LanguageSelector) => void): void {
    super.update((comp) => {
      if (updateFn) {
        updateFn(comp);
      } else {
        comp.langs = this.languages
        comp.currentLanguages = this.currentLanguages;
      }
    });
  }
}
