import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core';
import {SharedModule} from './shared/shared.module';
import {AfUiModule} from './ui-components/af-ui/af-ui.module';
import {LoggerModule} from 'ngx-logger';
import {environment} from '../environments/environment';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {InitModule} from './init.module';
import {HttpClientModule} from '@angular/common/http';
import {TwpubUiModule} from './ui-components/twpub-ui/twpub-ui.module';
import {AdminModule} from './admin/admin.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.logLevel
    }),
    RouterModule,
    InitModule,
    AdminModule,
    CoreModule,
    SharedModule,
    AfUiModule,
    TwpubUiModule
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
