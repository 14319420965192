import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {DigiArbetsformedlingenAngularModule} from '@digi/arbetsformedlingen-angular';
import {NGXLogger} from 'ngx-logger';
import {AfA11yStatementPageComponent, AfResultPageComponent, AfSearchPageComponent} from './pages';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ComponentDeclarationService} from '@twpub/core/services';
import {AfUiRoutingModule} from './af-ui-routing.module';
import {
  AfAdvancedSearchComponent,
  AfFooterComponent,
  AfHeaderComponent,
  AfLanguageDropdownComponent,
  AfPreambleComponent,
  AfResultListInfiniteComponent,
  AfResultListPaginationComponent,
  AfResultPaginationComponent,
  AfResultViewComponent,
  AfSearchFieldComponent
} from './components';
import {NgOptimizedImage} from '@angular/common';

@NgModule({
  declarations: [
    AfSearchPageComponent, AfResultPageComponent, AfA11yStatementPageComponent, AfFooterComponent, AfHeaderComponent,
    AfSearchFieldComponent, AfPreambleComponent, AfResultViewComponent, AfResultPaginationComponent,
    AfLanguageDropdownComponent, AfAdvancedSearchComponent, AfResultListInfiniteComponent,
    AfResultListPaginationComponent],
  imports: [AfUiRoutingModule, SharedModule, DigiArbetsformedlingenAngularModule, InfiniteScrollModule, NgOptimizedImage]
})

export class AfUiModule {
  static readonly SCHEME: string = 'DigiCore';
  readonly components = [
    AfSearchPageComponent, AfResultPageComponent, AfA11yStatementPageComponent,
    AfSearchFieldComponent, AfHeaderComponent, AfFooterComponent, AfPreambleComponent,
    AfLanguageDropdownComponent, AfAdvancedSearchComponent, AfResultListInfiniteComponent,
    AfResultListPaginationComponent, AfResultViewComponent
  ];

  /**
   * Register all components in this module with the component declaration service.
   * @param compService The component declaration service.
   * @param logger The logger.
   */
  constructor(compService: ComponentDeclarationService, logger: NGXLogger) {
    logger.debug('AfUiModule registers components...');
    this.components.forEach(c => compService.registerComponent(c, new c.Init()));
  }
}
