<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>TermWeb Connection</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="editConfiguration">
      <pub-termweb-connection-settings-form data-testid="form"
        [configuration]="editConfiguration" (saveConfiguration)="saveConfiguration($event)">
      </pub-termweb-connection-settings-form>
    </ng-container>
    <mat-accordion *ngIf="!editConfiguration && currentConfiguration">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span [ngClass]="'text-' + termwebMessageType">{{ termwebMessage }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <pub-termweb-connection-settings-display data-testid="display"
          [configuration]=currentConfiguration (editConfiguration)="setEditConfig($event)">
        </pub-termweb-connection-settings-display>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
