import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {BaseResultListComponent} from '@twpub/shared/components/base';
import {DisplayConcept, FieldProps, PaginationData, ResultList, TermSearchResult} from '@twpub/core/models';
import {MatPaginator} from '@angular/material/paginator';
import {ComponentConfiguration, ComponentInit, formatMessage, InitialConfig} from '@twpub/core/utils';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {Router} from '@angular/router';
import {ResultListProp} from '@twpub/core/constants';
import {LanguageService} from '@twpub/core/services/language.service';
import {AfUiModule} from '../../af-ui.module';

const {
  IncludeSynonyms,
  IncludeTranslations,
  Fields,
  InitialSearch,
  IncludeTermLink,
  IncludeResultLink,
  ResultLinkLabel,
  PageSize
} = ResultListProp;

@Component({
  selector: 'pub-af-result-list-pagination',
  templateUrl: './af-result-list-pagination.component.html',
  styleUrls: ['./af-result-list-pagination.component.scss']
})
export class AfResultListPaginationComponent extends BaseResultListComponent implements ResultList, OnInit, OnDestroy {
  @Input() selectedLang?: string;
  concept?: DisplayConcept;
  includeTermLink: boolean = false;
  includeTranslations = false;
  includeResultLink: boolean = false;
  sourceLangCode?: string;
  targetLangCode?: string;
  sourceLangName?: string;
  targetLangName?: string;

  private timeoutId: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren('resultItem', {read: ElementRef}) renderedItems!: QueryList<ElementRef>;
  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [
      {
        key: InitialSearch,
        label: 'Results visible on page load',
        controlType: ControlType.Boolean,
        description: 'Automatically list all terms when page is opened, before any search'
      },
      {
        key: IncludeSynonyms,
        label: 'Include synonym terms',
        controlType: ControlType.Boolean,
        description: 'Should a column with synonym terms be included in the result'
      },
      {
        key: IncludeTranslations,
        label: 'Include translations',
        controlType: ControlType.Boolean,
        description: 'Should a column with term(s) in selected target language be included in the result'
      },
      {
        key: IncludeTermLink,
        label: 'Term link to result page',
        controlType: ControlType.Boolean,
        description: 'Should there be a link from term name to result page'
      },
      {
        key: IncludeResultLink,
        label: 'Additional link to result page',
        controlType: ControlType.Boolean,
        description: 'Should there be a link below term to result page'
      },
      {
        key: ResultLinkLabel,
        label: 'Result page link text',
        controlType: ControlType.Textbox,
        description: 'Text for the link to result page. Use {term} to include the term name in the link text'
      },
      {
        key: Fields,
        label: 'Field names',
        controlType: ControlType.Textbox,
        description: 'Enter names of entry fields (concept and term level) to be included in the result list. Separate names by semi-colon (;)'
      },
      {
        key: PageSize,
        label: 'Page size',
        controlType: ControlType.Textbox,
        description: 'Number of results per page'
      }
    ];

    constructor() {
      super(AfResultListPaginationComponent, 'AfResultListPaginationComponent', 'Af Pagination Result List', [PageCompType.ResultList]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultList>(AfResultListPaginationComponent, this.name, initialConfig);
    }
  }

  private router: Router;
  private langService: LanguageService;
  private linkTextTemplate?: string;

  constructor(private injector: Injector) {
    super(injector);
    this.router = injector.get(Router);
    this.langService = injector.get(LanguageService);
  }

  ngOnInit(): void {
    this.includeTermLink = this.config.getBoolean(IncludeTermLink);
    this.includeResultLink = this.config.getBoolean(IncludeResultLink);
    this.includeTranslations = this.config.getBoolean(IncludeTranslations);
    this.linkTextTemplate = this.config?.getString(ResultLinkLabel);
    this.sessionObj.resultPaginator.pageSize = this.config?.getNumber(PageSize) || 10;
    this.onInit();
    this.fetchLangNames((langs) => {
      this.sourceLangCode = this.sessionObj.sourceLangs?.[0];
      this.targetLangCode = this.sessionObj.targetLangs?.[0];
      this.sourceLangName = this.dictService.getLanguageName(langs, this.sessionObj.sourceLangs?.[0]);
      this.targetLangName = this.dictService.getLanguageName(langs, this.sessionObj.targetLangs?.[0]);
    });
    this.performSearch(this.sessionObj.resultPaginator, true);
  }

  getLangCode(code: string = ''): string {
    return this.langService.getIso2Code(code);
  }

  public getLinkText(term: string): string {
    return formatMessage(this.linkTextTemplate || 'Visa hela termposten', {term});
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

  protected override performSearch(pData?: PaginationData, clear: boolean = false) {
    super.performSearch(pData, clear);
  }

  getFieldValue(res: TermSearchResult, fieldName: string): string {
    return res.fields?.find((d) => d.name === fieldName)?.value || '';
  }

  pageChange(pgData: PaginationData) {
    this.performSearch(pgData, true);
  }
}
