<ng-container *ngIf="visible">
  <mat-form-field *ngIf="sections && sections.length>1" [ngClass]="cssClassName">
    <mat-label>{{ label }}</mat-label>
    <mat-select #select [value]="getSelectedValue()" (selectionChange)="onSelect($event.value)"
                [multiple]="multipleSelect">
      <div *ngIf="multipleSelect" class="ctrl-select">
        <a mat-button [disabled]="allSelected" (click)="!allSelected && toggleAllSelection()" data-testid="select-all">
          <mat-icon fontIcon="check"></mat-icon>
          Select All</a>
      </div>
      <div *ngIf="multipleSelect" class="ctrl-select">
        <a mat-button [disabled]="singleSelected" (click)="!singleSelected && toggleAllSelection(false)" data-testid="select-none">
          <mat-icon fontIcon="clear"></mat-icon>
          None</a>
      </div>
      <mat-select-trigger *ngIf="allSelected">{{ allSelectedLabel }}</mat-select-trigger>
      <mat-option value='' [ngClass]="{'d-none':multipleSelect}">{{ allSelectedLabel }}</mat-option>
      <mat-option *ngFor="let section of sections" [value]="section.oid"
                  [disabled]="isDisabled(section)">{{ section.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
