import {Component, Input} from '@angular/core';
import {ALL_COMP_TYPES} from '@twpub/core/enums';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {ComponentDeclarationService} from '@twpub/core/services';
import {ConfigurableComponent} from '@twpub/core/models';

@Component({
  selector: 'pub-component-editors-list',
  templateUrl: './component-editors-list.component.html',
  styleUrls: ['./component-editors-list.component.scss']
})
export class ComponentEditorsListComponent {
  @Input() configuration!: ClientConfiguration;
  @Input() types = ALL_COMP_TYPES;

  constructor(private componentDeclarationService: ComponentDeclarationService) {
  }

  getConfiguration(compType: string): ComponentConfiguration<ConfigurableComponent> {
    return this.configuration.getConfigurationSafe(compType) ||
      this.componentDeclarationService.createComponentConfigurationForType(compType);
  }
}
