import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RefLink, RefLinkType} from '@twpub/core/models/ref-link';
import {ConceptSelection} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'pub-link-render',
  templateUrl: './link-render.component.html'
})
export class LinkRenderComponent {
  @Input() refLink!: RefLink;
  @Input() text: string = '';
  @Input() useRouterLink: boolean = false;

  @Output() conceptSelected = new EventEmitter<ConceptSelection>();

  constructor(private logger: NGXLogger) {
  }

  selectConcept(selection: ConceptSelection) {
    this.logger.trace('LinkRenderComponent.selectConcept:', {selection})
    this.conceptSelected.emit(selection);
  }

  protected readonly RefLinkType = RefLinkType;
}
