import {Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ClientProp} from '@twpub/core/constants';
import {SessionObject} from '@twpub/core/models';
import {ClientConfigurationService} from '@twpub/core/services/client-configuration.service';
import {SESSION_STORAGE} from '@ng-web-apis/common';
import {isEqual} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {ClientConfiguration} from '@twpub/core/utils';
import {BehaviorSubject, filter, map, Observable, take} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionStorage: Storage | undefined;
  private _sessionObj!: SessionObject;
  private clientConfigSubject = new BehaviorSubject<ClientConfiguration | undefined>(undefined);

  constructor(private injector: Injector, private logger: NGXLogger,
              private clientConfigService: ClientConfigurationService) {
    if (isPlatformBrowser(injector.get(PLATFORM_ID))) {
      this.sessionStorage = injector.get<Storage>(SESSION_STORAGE);
    }
    this._sessionObj = new SessionObject("en");
    this.clientConfigService.getDefaultConfiguration(undefined).subscribe({
      next: (config) => {
        this.clientConfigSubject.next(config);
        const foundLocale = config.props[ClientProp.Locale];
        this._sessionObj = new SessionObject(foundLocale);
      }
    });
  }

  initSession() {
    this.clientConfigSubject.asObservable().pipe(
      filter((config): config is ClientConfiguration => config !== undefined), // Ensure config is available
      take(1)
    ).subscribe({
      next: () => {
        const storedSession = this.sessionStorage?.getItem('sessionObj');
        let sessionObj$: Observable<SessionObject>;
        if (storedSession) {
          const parsedSession = JSON.parse(storedSession);
          sessionObj$ = this.setDefaultValues(true).pipe(
            map(defaultValues => {
              const defValuesObj = {...parsedSession, ...defaultValues, resultPaginator: parsedSession.resultPaginator};
              if(!isEqual(parsedSession, defValuesObj)) {
                return defValuesObj;
              }
              return parsedSession;
            })
          );
        } else {
          sessionObj$ = this.setDefaultValues();
        }

        sessionObj$.subscribe({
          next: (sessionObj) => {
            this.setSession(sessionObj);
          },
          error: (err) => {
            this.logger.error('Error initializing session:', err);
          }
        });
      },
      error: (err) => {
        this.logger.error('Error fetching client configuration:', err);
      }
    });
  }
  private saveSession(sessionObj: SessionObject = this._sessionObj) {
    this.sessionStorage?.setItem('sessionObj', JSON.stringify(sessionObj));
  }

  getSession(): SessionObject {
    return this._sessionObj;
  }

  setSession(sessionObj: SessionObject) {
    this._sessionObj = sessionObj;
    this.saveSession(sessionObj);
  }

  private setDefaultValues(useFixedValues: boolean = false): Observable<SessionObject> {
    return this.clientConfigSubject.asObservable().pipe(
      take(1),
      map(clientConfig => {
        if (!clientConfig) {
          throw new Error('ClientConfiguration is not available');
        }
        const foundLocale = clientConfig.props[ClientProp.Locale];
        const sessionObj: SessionObject = new SessionObject(foundLocale);
        let defValue: string | number | boolean | undefined;
        const {DictionarySelector, SourceLanguageSelector, TargetLanguageSelector, AdvancedSearch} = PageCompType;
        const defProps = [
          {compType: DictionarySelector, valType: Number, propName: 'dictId'},
          {compType: SourceLanguageSelector, valType: String, propName: 'sourceLangs'},
          {compType: TargetLanguageSelector, valType: String, propName: 'targetLangs'},
          {compType: AdvancedSearch, valType: String, propName: 'additionalField'}
        ];
        sessionObj.version = clientConfig.version;
        for (const defProp of defProps) {
          const compConfig = clientConfig.getConfigurationSafe(defProp.compType);
          defValue = compConfig?.getConfigValue('defaultValue', defProp.valType);
          const visible = compConfig?.getBoolean('visible');
          if (defValue && (!useFixedValues || !visible)) {
            switch (defProp.propName) {
              case SessionParam.DICT_ID:
                sessionObj[defProp.propName] = defValue as number;
                break;
              case SessionParam.SOURCE_LANGS:
              case SessionParam.TARGET_LANGS:
                sessionObj[defProp.propName] = [defValue as string];
                break;
              case SessionParam.ADDITIONAL_FIELD:
                sessionObj[defProp.propName] = ((defValue as String)?.toLowerCase() === 'true') ? compConfig?.getString('additionalField') : '';
                break;
              default:
                throw new Error('Unknown prop name:' + defProp.propName)
            }
          }
        }
        return sessionObj;
      })
    );
  }
}
