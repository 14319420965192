import {Component, Input, OnInit} from '@angular/core';
import {ConceptRelation, ConceptRelationAttribute} from '@twpub/core/models';
import {ConceptService, SharedStateService} from '@twpub/core/services';
import {Router} from '@angular/router';
import {FieldService} from '@twpub/core/services/field.service';

@Component({
  selector: 'pub-render-relation',
  templateUrl: './render-relation.component.html',
  styleUrls: ['./render-relation.component.scss']
})
export class RenderRelationComponent implements OnInit {
  @Input() relation!: ConceptRelation;
  @Input() isReversed: boolean = false;
  targetTermName: string = '';
  targetConceptId: number = 0;
  targetTermId: number = 0;
  imageFields: string[] = [];

  constructor(private sharedStateService: SharedStateService, private conceptService: ConceptService, private router: Router, private fieldService: FieldService) {
  }

  ngOnInit(): void {
    const srcLangs = this.sharedStateService.getSourceLangs();
    this.imageFields = this.fieldService.getImageFields();
    const relatedConceptId = this.getRelatedConceptId();
    this.conceptService.getTermsInLanguages(relatedConceptId, srcLangs).subscribe(terms => {
      this.targetConceptId = relatedConceptId;
      this.targetTermName = terms[0]?.name || '';
      this.targetTermId = terms[0]?.id || 0;
    })
  }

  private getRelatedConceptId = () => this.isReversed ? this.relation.sourceConceptId : this.relation.targetConceptId;

  getClassForRelAttr(attr: ConceptRelationAttribute, reverse?: boolean): string {
    const {
      TEMPORAL_CONCURRENT,
      SPATIAL_LEFT,
      SPATIAL_RIGHT,
      SPATIAL_UP,
      SPATIAL_DOWN,
      PARTITIVE_SUPERORDINATE,
      TEMPORAL_BEFORE,
      TEMPORAL_AFTER,
      GENERIC_SUBORDINATE,
      ASSOCIATIVE_DEFAULT,
      PARTITIVE_SUBORDINATE,
      GENERIC_SUPERORDINATE
    } = ConceptRelationAttribute;
    switch (attr) {
      case GENERIC_SUPERORDINATE: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'down' : 'up'}`;
      }
      case GENERIC_SUBORDINATE: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'up' : 'down'}`;
      }
      case PARTITIVE_SUPERORDINATE: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'down' : 'up'}`;
      }
      case PARTITIVE_SUBORDINATE: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'up' : 'down'}`;
      }
      case TEMPORAL_BEFORE: {
        return 'rel-font-icon fas fa-long-arrow-alt-left';
      }
      case TEMPORAL_AFTER: {
        return 'rel-font-icon fas fa-long-arrow-alt-right';
      }
      case TEMPORAL_CONCURRENT: {
        return 'rel-font-icon fas fa-undo-alt';
      }
      case SPATIAL_UP: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'down' : 'up'}`;
      }
      case SPATIAL_DOWN: {
        return `rel-font-icon fas fa-long-arrow-alt-${reverse ? 'up' : 'down'}`;
      }
      case SPATIAL_RIGHT: {
        return 'rel-font-icon fas fa-long-arrow-alt-right';
      }
      case SPATIAL_LEFT: {
        return 'rel-font-icon fas fa-long-arrow-alt-left';
      }
      case ASSOCIATIVE_DEFAULT: {
        return 'rel-font-icon fas fa-arrows-alt-h';
      }
    }
  }

  clickRelation(): void {
    this.router.navigate(['/entry', this.targetConceptId, this.targetTermId]);
    this.sharedStateService.selectConcept({conceptId: this.targetConceptId, termId: this.targetTermId});
  }
}
