import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {SearchField} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';

@Component({
  selector: 'pub-default-search-field',
  templateUrl: './default-search-field.component.html',
  styleUrls: ['./default-search-field.component.scss']
})
export class DefaultSearchFieldComponent extends BaseConfigurableComponent implements SearchField, OnInit {
  @Input() autoComplete: boolean = true
  @Output() searchFor = new EventEmitter<string>()

  searchTerm: string = ''

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;

    // override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD];

    constructor() {
      super(DefaultSearchFieldComponent, 'DefaultSearchFieldComponent', 'Default Search Field', [PageCompType.SearchField]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<SearchField>(DefaultSearchFieldComponent, this.name, initialConfig);
    }
  }

  ngOnInit(): void {
    this.searchTerm = this.sessionObj.searchTerm || '';
  }

  searchClick() {
    this.searchFor.emit(this.searchTerm)
  }
}
