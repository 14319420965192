import {Component, Injector, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ResultListDirective} from './result-list.directive';
import {ResultList} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-result-list-wrapper',
  templateUrl: './result-list-wrapper.component.html',
  styleUrls: ['./result-list-wrapper.component.scss']
})
export class ResultListWrapperComponent extends BaseWrapperComponent<ResultList> implements OnChanges {
  override type = PageCompType.ResultList;
  @ViewChild(ResultListDirective, {static: true}) pubResultList!: ResultListDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  override createComponent(): ResultList {
    const instance = super.createComponent();
    super.update();
    return instance;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes)) {
      super.update(undefined, changes);
    }
  }

  getViewContainerRef() {
    return this.pubResultList.viewContainerRef;
  }
}
