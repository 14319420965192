<label>{{ label }}</label><br>
<a [routerLink]="['/']" (click)="onSelect('')">All</a>
<ul *ngIf="visible">
  <li class="path" *ngFor="let dom of domains">
    <a [routerLink]="['/']" [ngClass]="{selected:isSelected(dom.id)}" (click)="onSelect(''+dom.id)">{{ dom.name }}</a>
    <ul *ngIf="isSelected(dom.id)">
      <li *ngFor="let subdom of dom.children">
        <a [routerLink]="['/']" [ngClass]="{selected:isSelected(subdom.id)}"
           (click)="onSelect(''+subdom.id)">{{ subdom.name }}</a>
      </li>
    </ul>
</ul>
