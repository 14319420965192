<ng-container *ngIf="visible">
  <mat-form-field appearance="outline" [ngClass]="['col-12 col-sm-12 col-md-8 col-lg-8 col-xl-5',cssClassName]">
    <input matInput [(ngModel)]="searchTerm" id="searchText" autocomplete="off" placeholder="{{label}}"
           [ngModelOptions]="{standalone: true}" (keyup.enter)="searchClick()"/>
    <button mat-icon-button matSuffix color="primary" (click)="searchClick()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</ng-container>

