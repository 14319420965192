/* eslint-disable dot-notation */
import {Component, Injector, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ActivatedRoute, Router} from '@angular/router';
import {ConceptSelection, SessionObject} from '@twpub/core/models';
import {ClientConfigurationService, ConceptService, SharedStateService} from '@twpub/core/services';
import {ClientConfiguration, cloneSession, ComponentConfiguration} from '@twpub/core/utils';
import {of, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from '@twpub/core/services/session.service';
import _ from 'lodash';

/**
 * Base class for pages holding session object.
 */
@Component({
  template: ''
})
export abstract class BasePageComponent implements OnInit {
  logger: NGXLogger
  config?: ComponentConfiguration<any>;
  clientConfig?: ClientConfiguration;
  isConfigurationOk: boolean = false;
  protected route: ActivatedRoute;
  private clientConfigService: ClientConfigurationService;
  private router: Router;
  private conceptService: ConceptService;
  private sessionService: SessionService;
  private sharedStateService: SharedStateService;

  get sessionObj(): SessionObject {
    return cloneSession(this.sessionService.getSession());
  }

  protected constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.conceptService = injectorObj.get(ConceptService);
    this.router = injectorObj.get(Router);
    this.route = injectorObj.get(ActivatedRoute);
    this.sessionService = injectorObj.get(SessionService);
    this.sharedStateService = injectorObj.get(SharedStateService);
    this.clientConfigService = injectorObj.get(ClientConfigurationService);
  }

  ngOnInit() {
    this.logger.debug('BaseWrapperComponent.ngOnInit:', {})
    this.route.data.subscribe({
      next: (config) => {
        this.clientConfig = config['clientConfig'];
        this.logger.debug('BaseWrapperComponent.ngOnInit:', {clientConfig: this.clientConfig});
      }
    })

    this.clientConfigService.hasTermWebConfiguration().subscribe({
      next: () => {
        this.isConfigurationOk = true;
        this.addClientCss();
        this.sessionService.initSession();
        this.initFromParameters();
        this.handleBackButton();
      },
      error: () => {
        this.isConfigurationOk = false;
        this.router.navigate(['/admin/home']);
      }
    });
  }

  private handleBackButton() {
    this.route.params.subscribe(() => this.initFromParameters());
  }

  initFromParameters() {
    const paramMap = this.getParamMap();
    const cidParam = paramMap.get('cid');
    const tidParam = paramMap.get('tid');
    const dictIdParam = paramMap.get('dictId');
    const cid = cidParam ? Number(cidParam) : undefined;
    const tid = tidParam ? Number(tidParam) : undefined;
    const dictId = dictIdParam ? Number(dictIdParam) : undefined;

    const {conceptId, termId} = this.sharedStateService._sessionObj;
    this.logger.debug('BasePageComponent.initFromParameters:', {cid, tid, conceptId, termId})
    const selection: ConceptSelection = {conceptId: cid, termId: tid};
    if (dictId) {
      this.sharedStateService.selectDictionary(dictId, selection);
    } else if (cid) {
      this.sharedStateService.selectConcept(selection);
    }
    this.findSourceLangFromConcept(selection).pipe(
      tap((codes) => {
        if (codes?.length) {
          if (!_.isEqual(codes, this.sessionObj.sourceLangs)) {
            this.sessionObj.sourceLangs = codes;
            this.sharedStateService.selectSourceLanguages(codes);
          }
        }
      })).subscribe();
  }

  getParamMap() {
    return this.route.snapshot.paramMap;
  }

  protected addClientCss() {
    const css = this.clientConfig?.css;
    if (css) {
      // Create style element and add css
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.setAttribute('id', 'clientConfigCss')
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  }

  private findSourceLangFromConcept(selection ?: ConceptSelection) {
    if (!selection?.conceptId) {
      return of([]);
    }
    return this.conceptService.getConcept(selection.conceptId).pipe(
      map((concept) => {
        const term = concept.terms.find(term => term.id === selection.termId);
        return term ? [term.languageCode] : [];
      })
    )
  }
}
