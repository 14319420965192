<!--<div class="container">-->
<h3 mat-dialog-title>Dictionary: <span class="readonly-value">{{ dictStat.name }}</span></h3>
<mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p>Dictionary ID: {{ dictStat.id }}</p>
        <p>{{ getStatusMessage() }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <pub-sections-panel [dictStat]="dictStat"></pub-sections-panel>
      </div>
      <div class="col-6">
        <pub-languages-panel [dictStat]="dictStat"></pub-languages-panel>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="deleteIndexPrompt()" color="warn" [disabled]="!isComplete()">
      <mat-icon>delete"</mat-icon>
      <span class="icon-text">Delete index</span>
    </button>

    <button mat-raised-button (click)="closeDialog()" color="primary">Close</button>
  </mat-dialog-actions>
</mat-dialog-content>
<!--</div>-->
