import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '@twpub/shared/components/base';
import {A11yPage} from '@twpub/core/models/wrappers/a11y-page';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {ClientProp, TITLE} from '@twpub/core/constants';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-a11y-statement-page',
  templateUrl: './af-a11y-statement-page.component.html',
  styleUrls: ['./af-a11y-statement-page.component.scss']
})
export class AfA11yStatementPageComponent extends BasePageComponent implements A11yPage, OnInit {
  title: string = 'Tillgänglighetsredogörelse för arbetsformedlingen.termbank.se';

  constructor(private injector: Injector, private titleService: Title, @Inject(DOCUMENT) private document: Document) {
    super(injector);
  }

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [];

    constructor() {
      super(AfA11yStatementPageComponent, 'AfA11yStatementPageComponent', 'AF Accessibility Statement page', [PageCompType.A11yPage]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<A11yPage>(AfA11yStatementPageComponent, this.name, initialConfig);
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    const titleName = this.clientConfig?.props[ClientProp.TitleName] || TITLE;
    const title = `${titleName} - Tillgänglighetsredogörelse`;
    this.titleService.setTitle(title);
    this.document.documentElement.lang = this.clientConfig?.props[ClientProp.Locale] || 'sv';
    const favicon = this.document.querySelector('#appFavicon');
    favicon?.setAttribute('href', 'af-favicon.ico');
  }
}
