import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {ConnectionStatus, TermwebConfiguration} from '@twpub/core/models';
import {BaseService} from '@twpub/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TermwebStatusService extends BaseService {
  constructor(private injector: Injector) {
    super(injector)
    this.logger.debug('TermwebStatusService.constructor: ')
  }

  public pingServer(): Observable<String> {
    return this.http.get<String>(this.url('/ping'), {responseType: 'text' as 'json'});
  }

  public getTermWebStatus(): Observable<ConnectionStatus> {
    return this.http.get<ConnectionStatus>(this.url('/status'));
  }

  public attemptConnection(config: TermwebConfiguration): Observable<ConnectionStatus> {
    return this.http.post<ConnectionStatus>(this.url('/try-connect'), config);
  }
}
