<div class="label">{{ field.label }}</div>
<div class="value">
  <ng-container *ngFor="let item of contentItems">
    <ng-container *ngIf="item.link">
      <ng-container *ngIf="item.isVirtualFile">
        <pub-attachment-render-wrapper [fileId]="item.fileId!"/>
      </ng-container>
      <ng-container *ngIf="!item.isVirtualFile">
        <pub-link-render [refLink]="item.link" [text]="item.content" [useRouterLink]="useRouterLink"
                         (conceptSelected)="selectConcept($event)"></pub-link-render>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!item.link">{{ item.content }}</ng-container>
  </ng-container>
</div>
