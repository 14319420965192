<digi-layout-container afVerticalPadding="true" *ngIf="!!results" [ngClass]="{'has-results': !!results}">
  <digi-typography>
    <h2>Results</h2>
    <ng-container *ngIf="isWaiting">
      <digi-loader-spinner></digi-loader-spinner>
    </ng-container>
    <ng-container *ngIf="!isWaiting">
      <digi-table *ngIf="results?.length" afSize="small">
        <table>
          <thead>
          <tr>
            <th>{{sourceLangName}}</th>
            <th *ngIf="includeSynonyms">Synonym</th>
            <ng-container *ngFor="let fieldName of fieldNames">
              <th>{{fieldName}}</th>
            </ng-container>
            <th *ngIf="includeTranslations">{{targetLangName}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let res of results; let i=index">
            <tr class="tr1" #resultItem id="res-{{i}}">
              <td>
                <ng-container *ngIf="includeTermLink">
                  <digi-link [afLinkContainer]="true">
                    <a [routerLink]="['/entry',res.term.conceptId, res.term.id]" [lang]="getLangCode(sourceLangCode)"
                       (click)="resultClick(res, i)">{{ res.term.name }}</a>
                  </digi-link>
                </ng-container>
                <ng-container *ngIf="!includeTermLink">
                  {{ res.term.name }}
                </ng-container>
              </td>
              <td *ngIf="includeSynonyms">
                <ng-container *ngFor="let synonym of res.synonyms">
                  {{synonym.name}}<br>
                </ng-container>
              </td>
              <ng-container *ngFor="let fieldName of fieldNames">
                <td>{{getFieldValue(res, fieldName)}}</td>
              </ng-container>
              <td *ngIf="includeTranslations" [lang]="getLangCode(targetLangCode)">{{res.translations?.[0]?.name}}</td>
            </tr>
            <tr *ngIf="includeResultLink" class="tr2">
              <td colspan="5">
                <digi-link [afLinkContainer]="true">
                  <a [routerLink]="['/entry',res.term.conceptId, res.term.id]">
                    <digi-icon-chevron-right slot="icon"></digi-icon-chevron-right>
                    {{ getLinkText(res.term.name) }}</a>
                </digi-link>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </digi-table>
      <pub-af-result-pagination *ngIf="results?.length" resultName="resultat" class="result-pagination"
                                [pgData]="resultPaginator" (pgEvent)="pageChange($event)">
      </pub-af-result-pagination>
      <p class="no-results" role="status" *ngIf="hasNoResults()">No results found for "{{sessionObj.searchTerm}}".</p>
    </ng-container>
  </digi-typography>
</digi-layout-container>
