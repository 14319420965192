<div class="container p-4">
  <div class="header-container">
    <h4>Dictionaries</h4>
    <div class="table-header">
      <button mat-icon-button color="primary" matTooltipPosition=right matTooltip="Refresh" (click)="refreshData()">
        <ng-container *ngIf="!isRefreshing">
          <mat-icon>refresh</mat-icon>
        </ng-container>
        <ng-container *ngIf="isRefreshing">
          <mat-spinner matTooltipPosition=right matTooltip="Working..." [diameter]="24"></mat-spinner>
        </ng-container>
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dictStats" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef>ID</th>
      <td mat-cell class="cell-numeric" *matCellDef="let stat"> {{stat.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td class="clickable" mat-cell *matCellDef="let stat" (click)="openDictionaryDetails(stat)">
        <span matTooltipPosition="right" [matTooltip]="getNameTooltip(stat)"> {{stat.name}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="languages">
      <th mat-header-cell *matHeaderCellDef> Languages</th>
      <td mat-cell *matCellDef="let stat"> {{stat.languages}} </td>
    </ng-container>

    <ng-container matColumnDef="twConceptCount">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef> TermWeb Concepts</th>
      <td mat-cell class="cell-numeric" *matCellDef="let stat"> {{ stat.termWebConceptCount }}</td>
    </ng-container>

    <ng-container matColumnDef="pubConceptCount">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef> Published Concepts</th>
      <td mat-cell class="cell-numeric" *matCellDef="let stat"> {{getConceptCount(stat)}} </td>
    </ng-container>

    <ng-container matColumnDef="syncStatus">
      <th mat-header-cell *matHeaderCellDef> Status</th>
      <td mat-cell *matCellDef="let stat">
        <pub-sync-status-indicator [status]="stat.deleted?'DELETED':stat.syncStats.status"
                                   [message]="stat.syncStats.statusMessage"></pub-sync-status-indicator>
      </td>
    </ng-container>

    <ng-container matColumnDef="syncDate">
      <th mat-header-cell *matHeaderCellDef> Last sync</th>
      <td mat-cell *matCellDef="let stat"> {{stat.syncStats.lastSyncDate | date: 'yyyy-MM-dd HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="syncTime">
      <th mat-header-cell class="cell-numeric" *matHeaderCellDef> Sync time</th>
      <td mat-cell class="cell-numeric" *matCellDef="let stat"> {{timeStr(stat.syncStats.lastSyncTimeMs)}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let stat">
        <pub-icon-button [tooltip]="isNotSynced(stat)?'Start indexing':'Check for updates'"
                         [disabled]="isInProgess(stat) || stat.deleted"
                         (buttonClick)="fetchUpdates(stat)"
                         [color]="isNotSynced(stat)?'warn':'primary'"
                         icon="sync"></pub-icon-button>

        <!--<button mat-icon-button color="primary" (click)="stat.visible=!stat.visible">
          <mat-icon>{{stat.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>-->
        <!--<ng-container *ngIf="!isNotSynced(stat)">
          <pub-icon-button [tooltip]="isNotSynced(stat)?'Start indexing':'Reindex all concepts'"
                           [disabled]="isInProgess(stat)" (buttonClick)="reindexDictionaryPrompt(stat)" color="warn"
                           icon="restart_alt"></pub-icon-button>
        </ng-container>-->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="(!row.visible || !isComplete(row))?'row-disabled':''">
    </tr>
  </table>
</div>
