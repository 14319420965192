import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(private logger: NGXLogger, protected override readonly router: Router,
              protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.authenticated = await this.keycloak.isLoggedIn();
    this.logger.debug('AuthGuard.authenticated: ', this.authenticated)
    if (!this.authenticated) {
      const redirectUri = window.location.origin + state.url;
      this.logger.debug('AuthGuard.isAccessAllowed: redirect='+ redirectUri)
      await this.keycloak.login({redirectUri});
    }
    return this.authenticated;
  }
}
